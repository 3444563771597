import React from 'react'
import { Strings } from '../configs'
import '../styles/Login.css'

export default function TextAreaAutoSize({ text, setText, containerClass, textAreaClass }) {
  // Count textarea height
  const textRowCount = text ? text.split('\n').length : 0
  const rows = textRowCount + 1

  return (
    <div className={containerClass}>
      <textarea rows={rows} className={textAreaClass} placeholder={Strings.inputProductDescription} onChange={(e) => setText(e.target.value)} value={text} />
    </div>
  )
}