import algoliasearch from 'algoliasearch'

const PROJECT_ID = 'whitebear-a05e2'          // Required - your Firebase project ID
const ALGOLIA_APP_ID = 'X9NQBVNXB2'     // Required - your Algolia app ID
const ALGOLIA_SEARCH_KEY = '551078bebfcfeb839779b9999712f58c'

export async function search(searchAttribute, price, data, requestOptions) {
  const client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY)
  let searchIndex = 'items'
  const options = {
    ...requestOptions,
  }
  switch (searchAttribute) {
    case 'price':
      searchIndex = 'search_by_price_new'
      options.filters = 'price = ' + data
      break
    case 'itemType':
      searchIndex = price ? (price === 'asc' ? 'search_by_type_asc' : 'search_by_type_dsc') : 'search_by_type_new'
      break
    default:
      searchIndex = price ? (price === 'asc' ? 'search_by_text_asc' : 'search_by_text_desc') : 'search_by_text_new'
      break
  }
  const index = client.initIndex(searchIndex) // sort_price_desc sort_price_asc
  const response = await index.search(data, options)
  return response
}

const defaultAttribute = [
  'name',
  'uuid',
  'price',
  'description',
]