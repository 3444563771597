import React from 'react'
import '../../styles/ShoppingCart/ShoppingCartScreen.css'
import WaitingItem from './WaitingItem'

export default function WaitingComponent({ waitData, deleteWaitingItem }) {
  return (
    <div className="shopping-cart-container">
      <div className="cart-page-product-header-tab">
        <div className="cart-item__cell-checkbox">
          <label className="stardust-checkbox">
            <svg className='stardust-remove-button' xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512">
              <g>
                <g>
                  <path d="M354.338,34.205h-61.71c-3.354-9.93-12.752-17.102-23.8-17.102h-17.547C243.359,6.451,230.813,0,217.521,0    s-25.839,6.451-33.76,17.102h-17.547c-11.048,0-20.446,7.172-23.8,17.102H80.701c-18.566,0-33.67,15.105-33.67,33.67v359.148    c0,18.566,15.105,33.67,33.67,33.67h171.023c4.427,0,8.017-3.589,8.017-8.017c0-4.427-3.589-8.017-8.017-8.017H80.701    c-9.725,0-17.637-7.912-17.637-17.637V67.875c0-9.725,7.912-17.637,17.637-17.637h60.394v26.188c0,4.427,3.589,8.017,8.017,8.017    H285.93c4.427,0,8.017-3.589,8.017-8.017V50.238h60.391c9.725,0,17.637,7.912,17.637,17.637v230.881    c0,4.427,3.589,8.017,8.017,8.017c4.427,0,8.017-3.589,8.017-8.017V67.875C388.008,49.309,372.904,34.205,354.338,34.205z     M277.914,68.409H157.129V42.252c0-0.011,0.001-0.02,0.001-0.031c0-0.005-0.001-0.011-0.001-0.015    c0.009-5.004,4.08-9.071,9.085-9.071h21.846c2.854,0,5.493-1.517,6.929-3.985c4.781-8.213,13.204-13.117,22.532-13.117    s17.751,4.904,22.532,13.117c1.435,2.467,4.075,3.985,6.929,3.985h21.846c4.999,0,9.067,4.059,9.085,9.055    c0,0.011-0.001,0.02-0.001,0.031c0,0.021,0.003,0.041,0.003,0.062V68.409z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M251.722,410.455H97.269V84.443h17.64c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017H89.253    c-4.427,0-8.017,3.589-8.017,8.017v342.046c0,4.427,3.589,8.017,8.017,8.017h162.47c4.427,0,8.017-3.589,8.017-8.017    C259.739,414.044,256.15,410.455,251.722,410.455z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M345.787,68.409h-25.653c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h17.637v214.311    c0,4.427,3.589,8.017,8.017,8.017s8.017-3.589,8.017-8.017V76.426C353.804,71.999,350.214,68.409,345.787,68.409z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M294.48,171.023h-85.511c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h85.511    c4.427,0,8.017-3.589,8.017-8.017S298.908,171.023,294.48,171.023z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M268.827,136.818h-59.858c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h59.858    c4.427,0,8.017-3.589,8.017-8.017S273.254,136.818,268.827,136.818z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M412.738,448.433l-33.281-33.281v-53.687c0-4.427-3.589-8.017-8.017-8.017c-4.427,0-8.017,3.589-8.017,8.017v57.007    c0,2.126,0.844,4.166,2.348,5.668l35.629,35.629c1.565,1.565,3.617,2.348,5.668,2.348s4.103-0.782,5.668-2.348    C415.869,456.639,415.869,451.563,412.738,448.433z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M371.44,324.944c-51.572,0-93.528,41.956-93.528,93.528S319.869,512,371.44,512s93.528-41.956,93.528-93.528    S423.012,324.944,371.44,324.944z M371.44,495.967c-42.731,0-77.495-34.764-77.495-77.495s34.764-77.495,77.495-77.495    s77.495,34.764,77.495,77.495S414.172,495.967,371.44,495.967z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M176.49,163.55c-3.831-2.217-8.736-0.909-10.954,2.924c-1.624,2.806-4.64,4.548-7.875,4.548    c-5.01,0-9.086-4.076-9.086-9.086s4.076-9.086,9.086-9.086c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017    c-13.851,0-25.119,11.268-25.119,25.119s11.268,25.119,25.119,25.119c8.938,0,17.273-4.81,21.753-12.553    C181.632,170.671,180.322,165.767,176.49,163.55z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M294.48,256.534h-85.511c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h85.511    c4.427,0,8.017-3.589,8.017-8.017S298.908,256.534,294.48,256.534z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M268.827,222.33h-59.858c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h59.858    c4.427,0,8.017-3.589,8.017-8.017S273.254,222.33,268.827,222.33z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M176.49,249.061c-3.831-2.218-8.736-0.91-10.954,2.924c-1.624,2.806-4.64,4.548-7.875,4.548    c-5.01,0-9.086-4.076-9.086-9.086c0-5.01,4.076-9.084,9.086-9.084c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017    c-13.851,0-25.119,11.268-25.119,25.119s11.268,25.119,25.119,25.119c8.938,0,17.273-4.81,21.753-12.553    C181.632,256.183,180.323,251.279,176.49,249.061z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M268.827,342.046h-59.858c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h59.858    c4.427,0,8.017-3.589,8.017-8.017S273.254,342.046,268.827,342.046z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M268.827,307.841h-59.858c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h59.858    c4.427,0,8.017-3.589,8.017-8.017S273.254,307.841,268.827,307.841z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M176.49,334.572c-3.831-2.219-8.736-0.909-10.954,2.924c-1.624,2.806-4.64,4.548-7.875,4.548    c-5.01,0-9.086-4.076-9.086-9.086c0-5.01,4.076-9.086,9.086-9.086c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017    c-13.851,0-25.119,11.268-25.119,25.119c0,13.851,11.268,25.12,25.119,25.12c8.938,0,17.273-4.81,21.753-12.553    C181.632,341.694,180.323,336.79,176.49,334.572z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M217.607,34.205h-0.086c-4.427,0-7.974,3.589-7.974,8.017c0,4.427,3.631,8.017,8.059,8.017    c4.427,0,8.017-3.589,8.017-8.017C225.624,37.794,222.035,34.205,217.607,34.205z"/>
                </g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
            </svg>
          </label>
        </div>
        <div className="cart-page-product-header__product">Đơn Hàng</div>
      </div>
      <div>
        {waitData.map((item) => (
          <WaitingItem key={item.uuid} item={item} deleteWaitingItem={deleteWaitingItem} />
        ))}
      </div>
    </div>
  )
}
