import React from 'react'
import Icon from 'awesome-react-icons'
import { Strings } from '../configs'

const NavItem = [
  {
    title: 'Home',
    itemId: '/',
    elemBefore: () => <Icon name="coffee" />
  },
  {
    title: Strings.shoppingCart,
    itemId: '/shopping',
    elemBefore: () => <Icon name="inbox" />,
  },
  {
    title: Strings.answerQuestion,
    itemId: '/question',
    elemBefore: () => <Icon name="help-circle" />
  },
]

export default NavItem
