import React from 'react'
import '../styles/Home/Home.css'
import UserDetailPage from '../components/Detail/UserDetailPage'
import Header from '../common/Header'

const UserDetail = () => {
  return (
    <Header>
      <UserDetailPage />
    </Header>
  )
}

export default UserDetail