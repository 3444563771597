import React, { Component } from 'react'
import { Strings } from '../configs'

class UploadImage extends Component {
  constructor(props) {
    super(props)
  }

  handleChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      const newList = []
      for (let index = 0; index < e.target.files.length; index++) {
        newList.push(e.target.files[index])
      }
      this.props.onSelectImage(newList)
    }
  };

  render() {
    return (
      <div>
        <div>
          <div className="user-name-container">
            <span>{Strings.inputProductImage}</span>
            <input multiple type="file" accept='video/*, image/*' onChange={this.handleChange} />
          </div>
        </div>
      </div>
    )
  }
}

export default UploadImage