import React from 'react'
import '../../styles/ShoppingCart/ShoppingCartScreen.css'
import ShippingItem from './ShippingItem'

export default function ShippingComponent({ shipData }) {
  return (
    <div className="shopping-cart-container">
      <div>
        {shipData.map((item) => (
          <ShippingItem key={item.uuid} item={item} />
        ))}
      </div>
    </div>
  )
}
