import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { isMobile } from 'react-device-detect'
import { getItemByID, addItemToCart, auth } from '../../configs/FireBase'
import { useWindowDimensions } from '../../hooks'
import LoadingOverlay from 'react-loading-overlay'
import { Strings, priceFormatter } from '../../configs'
import '../../styles/Detail/DetailPage.css'
import { itemGuarantee } from '../../configs/ArgumentDoc'
import { showAlert } from '../../common/common'
import { ImageVideoComponentDetail, ImageBellowVideoDetailScreen } from '../../common/ImageVideoComponent'
import DetailImageList from './DetailImageList'

export default function DetailPage() {
  // setup
  const history = useHistory()
  const location = useLocation()
  const user = useSelector(state => state.app.user)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(null)
  const values = queryString.parse(location.search)
  const windowData = useWindowDimensions()
  const [error, setError] = useState('')
  const imageWidth = isMobile ? windowData.width : 320

  // Shopping
  const [numberRental, setNumberRental] = useState(1)
  useEffect(() => {
    onLoadDetail()
  }, [location])

  async function onLoadDetail() {
    window.scrollTo(0, 0)
    setIsLoading(true)
    const responseData = await getItemByID(values.id)
    setData(responseData)
    setIsLoading(false)
  }

  function changeNumberRental(increase) {
    if (increase) {
      if (numberRental < data.inventory) {setNumberRental(numberRental + 1)}
    } else {
      if (numberRental > 1) {setNumberRental(numberRental - 1)}
    }
  }
  function onInputNumberRental(number) {
    if (number > 0 && number <= data.inventory) {setNumberRental(number)}
  }

  async function onAddToCart() {
    if (!user) {
      showAlert(Strings.pleaseLogin, Strings.youMustLoginToAddToCart, Strings.login, Strings.cancel, () => {
        history.push('/login')
      })
    } else {
      setIsLoading(true)
      const result = await addItemToCart(auth.currentUser.uid, values.id, numberRental, data.inventory)
      if (!result) {
        showAlert(Strings.addError, Strings.excessOfAuthorizedOrdersOrOutOfStock, 'OK')
      } else {
        showAlert(Strings.addToCartSuccess, '', 'OK')
      }
      setIsLoading(false)
    }
  }
  async function onByNow() {
    if (!user) {
      showAlert(Strings.pleaseLogin, Strings.youMustLoginToAddToCart, Strings.login, Strings.cancel, () => {
        history.push('/login')
      })
    } else {
      setIsLoading(true)
      const result = await addItemToCart(auth.currentUser.uid, values.id, 1, data.inventory)
      if (!result) {
        setError(Strings.excessOfAuthorizedOrdersOrOutOfStock)
      } else {
        setError('')
        history.push('/shopping')
      }
      setIsLoading(false)
    }
  }

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text='Loading...'
    >
      {data ? <div className='detail-page-container'>
        <div className='product-briefing'>
          <DetailImageList item={data} />
          <div className='product-detail-container'>
            <div className='product-detail-content'>
              <div className='product-title'>{data.name}</div>
            </div>
            <div className='product-price-container'>
              <div className='product-price'>{priceFormatter.format(data.price)}</div>
            </div>
            {data.guarantee ? <div className='product-guarantee'>{Strings.guarantee} {itemGuarantee[data.guarantee]}</div> : null}
            <div className='product-number-container'>
              <div className='product-number'>{Strings.inputNumber}</div>
              <div className="product-count-number">
                <button onClick={() => changeNumberRental(false)} className="plus-button1">
                  <svg enableBackground="new 0 0 10 10" viewBox="0 0 10 10" x="0" y="0" className="plus-svg-icon">
                    <polygon points="4.5 4.5 3.5 4.5 0 4.5 0 5.5 3.5 5.5 4.5 5.5 10 5.5 10 4.5"></polygon>
                  </svg>
                </button>
                <input onChange={(e) => onInputNumberRental(parseInt(e.target.value))} className="plus-button1 plus-button2" type="number" value={numberRental} />
                <button onClick={() => changeNumberRental(true)} className="plus-button1">
                  <svg enableBackground="new 0 0 10 10" viewBox="0 0 10 10" x="0" y="0" className="plus-svg-icon"><polygon points="10 4.5 5.5 4.5 5.5 0 4.5 0 4.5 4.5 0 4.5 0 5.5 4.5 5.5 4.5 10 5.5 10 5.5 5.5 10 5.5"></polygon></svg>
                </button>
              </div>
              <div className='product-number'>{Strings.stocking}: {data.inventory}</div>
            </div>
            <div className='user-detail-error-label'>{error}</div>
            <div className='add-to-cart-container'>
              <button onClick={onAddToCart} type="button" className="add-to-cart" aria-disabled="false">
                <svg enableBackground="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0" className="add-to-cart-svg"><g><g><polyline fill="none" points=".5 .5 2.7 .5 5.2 11 12.4 11 14.5 3.5 3.7 3.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"></polyline><circle cx="6" cy="13.5" r="1" stroke="none"></circle><circle cx="11.5" cy="13.5" r="1" stroke="none"></circle></g><line fill="none" strokeLinecap="round" strokeMiterlimit="10" x1="7.5" x2="10.5" y1="7" y2="7"></line><line fill="none" strokeLinecap="round" strokeMiterlimit="10" x1="9" x2="9" y1="8.5" y2="5.5"></line></g></svg>
                <span>{Strings.addToCart}</span>
              </button>
              <button onClick={onByNow} type="button" className="by-now" aria-disabled="false">{Strings.byNow}</button>
            </div>
          </div>
        </div>
        <div className='line-title'>{Strings.productDescription}</div>
        <div className='text-data-container'>
          <div className='text-data'>{data.description}</div>
        </div>
        <div className="detail-page-bottom" />
      </div> : 
        <div className='detail-screen-place-holder' />
      }
    </LoadingOverlay>
  )
}