
import { useSelector } from 'react-redux'
import React from 'react'
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css'
import '../styles/NavSlidebar.css'
import { Strings } from '../configs'

const NavTitle = React.memo(() => {
  const user = useSelector(state => state.app.user)
  return (
    <div className="flex items-center justify-center text-center py-6" style={{ flexDirection: 'column' }}>
      {user && <img 
        src={user.photoURL}
        style={{ borderRadius: 50, width: 100, height: 100 }}
        alt="new"
      />}
      {user && <span className="mx-2 text-2xl font-semibold text-red-300">{user.displayName}</span>}
      {user && <span className="mx-1 text-xs font-semibold text-gray-600">{user.email}</span>}
      {!user && <span style={{ color: '#FF00B1' }} className="mx-2 text-3xl font-serif">{Strings.shopName}</span>}
    </div>
  )
}
)

export default NavTitle