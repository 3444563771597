import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import '../styles/Home/Home.css'
import NavBar from '../components/Home/NavBar'
import { isMobile } from 'react-device-detect'
import SearchBar from '../components/Home/SearchBar'
import Footer from '../components/Home/Footer'
import { setFirstTimeTutorial, setIsShowLoginModal } from '../store/actions'
import { ToastContainer, toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

export default function Header({ children, showShortBar }) {
  const [marginHeight, setMarginHeight] = useState(isMobile ? 124 : 106)
  const user = useSelector(state => state.app.user)
  const isFirstTime = useSelector(state => state.app.isFirstTime)
  const isShowLoginModal = useSelector(state => state.app.isShowLoginModal)
  const dispatch = useDispatch()
  let headerElement = null
  const history = useHistory()
  useEffect(() => {
    toast.error(
      <ToastContent title="Đã có app trên điện thoại!" content="Tải app ngay!"/>,
      {
        onClick: () => history.push('/app')
      }
    )
    if (!user) {
      if (!isShowLoginModal) {toast.dark(
        <ToastContent title="Bạn chưa đăng nhập!" content="Vui lòng đăng nhập để mua hàng!" bellowContent="Click vào đây để đăng nhập bằng Facebook hoặc chọn đăng nhập ở góc trên bên phải!" />,
        {
          onClose: dispatch(setIsShowLoginModal(true))
        }
      )
      }
    }
    else {
      if (!isFirstTime) {toast.info(
        <ToastAddCartContent title={`Xin chào ${user.displayName}!`} content="Nhấn vào icon bên dưới mặt hàng để thêm vào giỏ hàng nhanh." />,
        {
          onClose: dispatch(setFirstTimeTutorial(true))
        }
      )
      }
    }
  }, [user])
  useEffect(() => {
    if (headerElement && headerElement.clientHeight && headerElement.clientHeight >= 50) {
      setMarginHeight(headerElement.clientHeight)
    }
  })
  return (
    <div>
      <div>
        <div style={{ paddingTop: marginHeight }} className='full-body'>
          <div>
            <div  ref={(ref) => headerElement = ref} id="header">
              <NavBar />
              <SearchBar showShortBar={showShortBar} />
            </div>
          </div>
          {children}
          <Footer />
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={20000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

function ToastContent({ title, content, bellowContent }) {
  const history = useHistory()
  return (
    <div onClick={() => history.push('/login')} style={{ fontSize: isMobile ? 12 : 14 }}>
      <div>{title}</div>
      <div>{content}</div>
      {bellowContent ? <div style={isMobile ? { marginRight: 50 } : {}}>{bellowContent}</div> : null}
    </div>
  )
}

function ToastAddCartContent({ title, content, bellowContent }) {
  return (
    <div style={{ fontSize: isMobile ? 12 : 14 }}>
      <div>{title}</div>
      <div style={isMobile ? { marginRight: 50 } : {}}>{content}</div>
      <div className='plus-unit-item-walk-through'>
        <svg className='plus-unit-image' enableBackground="new 0 0 15 15" viewBox="0 0 15 15"><path d="m .5.5h2.2l2.5 10.5h7.2l2.1-7.5h-10.8" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"></path><circle cx="6" cy="13.5" r="1"></circle><circle cx="11.5" cy="13.5" r="1"></circle><path d="m7.5 7h3" fill="none" strokeLinecap="round" strokeMiterlimit="10"></path><path d="m9 8.5v-3" fill="none" strokeLinecap="round" strokeMiterlimit="10"></path></svg>
      </div>
    </div>
  )
}