import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import '../../styles/Home/NavBar.css'
import { Strings } from '../../configs'
import 'react-toastify/dist/ReactToastify.css'

export default function NavBar() {
  return (
    <div className="navbar-wrapper container-wrapper">
      <div className="container-navbar">
        <div className="v-center">
          <a href='/' className="nav-top-title">{Strings.shopName}</a>
          <div className="nav-top-left-icon-container nav-top-link">
            <a className="nav-top-left-icon facebook-image" title="Kết nối Facebook" href='https://www.facebook.com/whitebearentitlement' target="_blank" rel="noreferrer"/>
            <a className="nav-top-left-icon instagram-image" title="Kết nối Instagram" href='https://www.instagram.com/whitebearentitlement/' target="_blank" rel="noreferrer"/>
            {`${window.navigator.platform.toString()}`.includes('Linux') ? <a href='market://details?id=com.whitebearapp' className="nav-top-download" target="_blank" rel="noreferrer">Tải App</a> : <a href='/app' className="nav-top-download" target="_blank" rel="noreferrer">Tải ứng dụng</a>}
          </div>
          <UserTop />
        </div>
        
      </div>
    </div>
  )
}

function UserTop() {
  const user = useSelector(state => state.app.user)
  const history = useHistory()
  function onClickButton() {
    if (!user) {history.push('/login')}
    else {
      history.push('/userDetail')
    }
  }
  return (
    <div className="container-navbar-right">
      <div className="nav-top-link">
        {user && <img 
          src={user.photoURL}
          style={{ borderRadius: 10, width: 20, height: 20, marginRight: 5 }}
        />}
        <button onClick={onClickButton} className="white-bear-drawer">{user ? user.displayName : Strings.login}</button>
      </div>
    </div>
  )
}
