import React from 'react'
import '../styles/Home/Home.css'
import SearchPage from '../components/Search/SearchPage'
import Header from '../common/Header'

const SearchScreen = () => {
  return (
    <Header>
      <SearchPage />
    </Header>
  )
}

export default SearchScreen