import { useSelector, useDispatch } from 'react-redux'
import React from 'react'
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css'
import '../styles/NavSlidebar.css'
import google from '../assets/google.png'
import facebook from '../assets/facebook.png'
import { signInWithFacebook, signInWithGoogle, logOut } from '../configs/FireBase'
import { useWindowDimensions } from '../hooks'
import { updateUser } from '../store/actions'
import { Strings } from '../configs'

export default function NavBottom() {
  const dispatch = useDispatch()
  const user = useSelector(state => state.app.user)
  const { height } = useWindowDimensions()

  function onFaceLogin() {
    signInWithFacebook((user) => saveUserData(user))
  }
  function onGoogleLogin() {
    signInWithGoogle((user) => saveUserData(user))
  }
  function onLoutOut() {
    logOut()
    dispatch(updateUser(null))
  }
    
  function saveUserData(user) {
    if (user) {
      dispatch(updateUser({
        displayName: user.displayName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        photoURL: getUserPhotoUrl(user.photoURL, user.accessToken),
        accessToken: user.accessToken,
      }))
    }
    
  }

  function getUserPhotoUrl(photoURL, accessToken) {
    if (accessToken) {return `${photoURL}?access_token=${accessToken}&type=large`}
    return photoURL
  }

  if (height >= 400 && !user) {return (
    <div style={{ alignItems: 'center', justifyContent: 'center', marginLeft: 20, marginRight: 20, marginTop: 40 }}>
      <p style={{ textAlign: 'center' }}>{Strings.pleaseLogin}!</p>
      <button className="loginButton" onClick={onGoogleLogin}>
        <img src={google} />
      </button>
      <button className="loginButton" onClick={onFaceLogin} >
        <img src={facebook} />
      </button>
    </div>
  )}
  if (height >= 400 && user) {
    return (
      <div style={{ flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 40 }}>
        <button onClick={onLoutOut}>
          <p style={{ right: 20, textAlign: 'center', color: 'red' }}>{Strings.logOut}</p>
        </button>
      </div>
    )
  }
}
