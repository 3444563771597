import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import '../../styles/Home/SearchBar.css'
import bearImage from '../../assets/whitebearlazy.png'
import { itemTypeDoc } from '../../configs/ArgumentDoc'
import { realDatabase } from '../../configs/FireBase'
import SearchShortBar from './SearchShortBar'
import { showAlert } from '../../common/common'
import { Strings } from '../../configs'

function SearchBar({ showShortBar }) {
  const history = useHistory()
  const user = useSelector(state => state.app.user)
  const [searchText, setSearchText] = useState('')
  const [numberCartItem, setNumberCartItem] = useState(0)

  useEffect(() => {
    if (user) {
      var countRef = realDatabase.ref('users/' + user.uid + '/cart')
      countRef.on('value', (snapshot) => {
        if (snapshot.val()) {setNumberCartItem(Object.values(snapshot.val()).length)}
      })
    }
  }, [])

  async function onSearch() {
    history.push(`/search?searchText=${searchText}&searchType=0&page=0`) // searchType: 0 = search by Text, 1 = search by Price, 2 = search by Type
  }

  function onClickCart() {
    if (user) {
      history.push('/shopping')
    } else {
      showAlert('Giỏ Hàng', 'Vui lòng đăng nhập để vào giỏ hàng!', Strings.login, Strings.cancel, () => {
        history.push('/login')
      })
    }
  }
  return (
    <div className="container-wrapper header-with-search-wrapper">
      <div className="header-with-search">
        <a className="header-with-search-logo-section" href='/'>
          <img className="header-with-search-logo-svg" src={bearImage} />
        </a>
        <div className="header-with-search__search-section">
          <div className="white-bear-search-bar">
            <div className="white-bear-searchbar__main">
              <div role="search" autoComplete='off' className="white-bear-searchbar-input">
                <input
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onSubmit={onSearch}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSearch()
                      document.activeElement.blur()
                    }
                  }}
                  className="searchbar-input__input" aria-label='Dây Sạc' placeholder='Dây Sạc'></input>
              </div>
            </div>
            <button onClick={onSearch} type='button' className="btn-s">
              <svg  xmlns="http://www.w3.org/2000/svg" height='19' viewBox='0 0 19 19' width='19' className="svg-icon">
                <g fillRule='evenodd' stroke='none' strokeWidth='1'>
                  <g transform='translate(-1016 -32)'>
                    <g>
                      <g transform='translate(405 21)'>
                        <g transform='translate(611 11)'>
                          <path d='m8 16c4.418278 0 8-3.581722 8-8s-3.581722-8-8-8-8 3.581722-8 8 3.581722 8 8 8zm0-2c-3.3137085 0-6-2.6862915-6-6s2.6862915-6 6-6 6 2.6862915 6 6-2.6862915 6-6 6z'></path>
                          <path d='m12.2972351 13.7114222 4.9799555 4.919354c.3929077.3881263 1.0260608.3842503 1.4141871-.0086574.3881263-.3929076.3842503-1.0260607-.0086574-1.414187l-4.9799554-4.919354c-.3929077-.3881263-1.0260608-.3842503-1.4141871.0086573-.3881263.3929077-.3842503 1.0260608.0086573 1.4141871z'></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </button>
          </div>
          {!isMobile && <SearchShortBar itemTypeDoc={itemTypeDoc} />}
        </div>
        <div className='header-with-search__cart-wrapper'>
          <div className='stardust-popover'>
            <div className='cart-drawer-container'>
              <div role='button' className='cart-drawer flex v-center' onClick={onClickCart}>
                <svg viewBox='0 0 26.6 25.6' className='icon-shop-cart'>
                  <polyline fill="none" points="2 1.7 5.5 1.7 9.6 18.3 21.2 18.3 24.6 6.1 7 6.1" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2.5"></polyline>
                  <circle className='circle-shop-cart' cx="10.7" cy="23" r="2.2" stroke="none"></circle>
                  <circle className='circle-shop-cart' cx="19.7" cy="23" r="2.2" stroke="none"></circle>
                </svg>
                {numberCartItem ? <div className="cart-number-badge">{numberCartItem}</div> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMobile && showShortBar && <SearchShortBar itemTypeDoc={itemTypeDoc} />}
    </div>
  )
}

export default SearchBar