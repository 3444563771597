import React from 'react'
import ScrollMenu from 'react-horizontal-scrolling-menu'

export function ImageVideoComponent({ item, className, style, onClick }) {
  if (item.image.constructor === Array) {
    if (item.image[0] && item.image[0].type === 2) { // video
      return (
        <video onClick={onClick} className={className} style={style} autoPlay playsInline loop muted src={item.image[0].url}>
          Your browser does not support the video tag.
        </video>
      )
    } else { // image
      return (
        <img onClick={onClick} className={className} style={style} src={item.image[0] ? item.image[0].url : ''} />
      )
    }
  }
  return (
    <img onClick={onClick} className={className} style={style} src={item.image} />
  )
}

export function ImageVideoComponentDetail({ image, className, style, onClick }) {
  if (!image) {
    return null
  }
  if (image.type === 2) { // video
    return (
      <video controls onClick={onClick} className={className} style={style} autoPlay playsInline src={image.url}>
        Your browser does not support the video tag.
      </video>
    )
  } else { // image
    return (
      <img onClick={onClick} className={className} style={style} src={image.url ? image.url : image} />
    )
  }
}

export function ImageBellowVideoDetailScreen({ images, onChangeImage }) {
  if (images.constructor === Array) {
    return (
      <ScrollMenu
        arrowLeft={<LeftArrow />}
        arrowRight={<RightArrow />}
        menuClass='detail-image-bellow'
        data={images.map((image, index) => (
          <SmallImage key={index} image={image} onChangeImage={onChangeImage} />
        ))}
      />
    )
  }
  return null
}

function RightArrow() {
  return (
    <div role='button' className='small-image-detail-see-more'>
      <svg className='small-image-detail-see-more-icon' enableBackground="new 0 0 13 21" viewBox="0 0 13 21"><path stroke="none" d="m11.1 9.9l-9-9-2.2 2.2 8 7.9-8 7.9 2.2 2.1 9-9 1-1z"></path></svg>
    </div>
  )
}
function LeftArrow() {
  return (
    <div role='button' className='small-image-detail-see-more'>
      <svg enableBackground="new 0 0 13 20" viewBox="0 0 13 20" className='small-image-detail-see-more-icon'><path stroke="none" d="m4.2 10l7.9-7.9-2.1-2.2-9 9-1.1 1.1 1.1 1 9 9 2.1-2.1z"></path></svg>
    </div>
  )
}

function SmallImage({ image, onChangeImage }) {
  if (image.type === 2) { // video
    return (
      <video onClick={() => onChangeImage(image)} autoPlay playsInline className='small-image-item' muted src={image.url}>
        Your browser does not support the video tag.
      </video>
    )
  } else { // image
    return (
      <img onClick={() => onChangeImage(image)} className='small-image-item' src={image.url} />
    )
  }
}
