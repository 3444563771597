import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import { Strings, priceFormatter } from '../../configs'
import { getItemFromCart, getUserInformation, updateUser, addToPayment } from '../../configs/FireBase'
import '../../styles/ShoppingCart/PaymentScreen.css'
import { city } from '../../configs/data'
import { showAlert } from '../../common/common'

export default function DeleteData() {
  const user = useSelector((state) => state.app.user)
  const [data, setData] = useState([])
  const [userData, setUserData] = useState({})
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)

  // user information
  const [phone, setPhone] = useState('')
  const [cityAddress, setCityAddress] = useState('')
  const [address, setAddress] = useState('')
  const [message, setMessage] = useState('')

  useEffect(() => {
    window.scrollTo(0,0)
    getData()
  }, [])

  async function getData() {
    setIsLoading(true)
    const receiveData = await getUserInformation(user.uid)
    setUserData(receiveData)
    if (receiveData.phoneNumber) {
      setPhone(receiveData.phoneNumber)
    }
    if (receiveData.address) {
      setAddress(receiveData.address)
    }
    if (receiveData.cityAddress) {
      setCityAddress(receiveData.cityAddress)
    } else {
      setCityAddress(city[0])
    }
    const serverData = await getItemFromCart(user.uid)
    if (serverData && serverData.length > 0) {setData(serverData)}
    else {
      showAlert(Strings.doNotHaveDataToPay, Strings.pleaseAddTheOrder, Strings.returnToHome, null, () => {
        setIsLoading(false)
        history.push('/')
      })
    }
    setIsLoading(false)
  }

  function countTotalMoney(listItem) {
    let total = 0
    for (let index = 0; index < listItem.length; index++) {
      total += listItem[index].price * listItem[index].cartNum
      
    }
    return priceFormatter.format(total)
  }
  async function onClickOrder() {
    setIsLoading(true)
    if (phone.length < 10 || !phone.match(/([0])\d{9}/g)) {
      showAlert(Strings.wrongInformation, Strings.pleaseCorrectThePhoneNumber, 'OK', null, () => {})
      setIsLoading(false)
      return
    }
    if (address.length < 10) {
      showAlert(Strings.wrongInformation, Strings.pleaseCorrectTheAddress, 'OK', null, () => {})
      setIsLoading(false)
      return
    }
    if (!data || data.length === 0) {
      showAlert(Strings.doNotHaveDataToPay, Strings.pleaseAddTheOrder, Strings.returnToHome, null, () => {
        setIsLoading(false)
        history.push('/')
      })
      return
    }
    await updateUser({ phoneNumber: phone, address, cityAddress, uid: userData.uid })
    await addToPayment(userData.uid, data, address, cityAddress, phone, message, userData.email || '', userData.displayName, userData.photoURL || '', countTotalMoney(data))
    setIsLoading(false)
    showAlert(Strings.buySuccessfully, Strings.shopWillContactSoon, Strings.continueToShopping, null, () => {
      history.push('/')
    })
  }

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text='Đang xử lý...'
    >
      <div className='full-body'>
        <div className="app-container">
          <div className="navigation-bar">
            <a onClick={() => history.goBack()}>
              <svg viewBox="0 0 22 17" className="back-button">
                <g stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(-3, -6)">
                  <path d="M5.78416545,15.2727801 L12.9866648,21.7122915 C13.286114,22.0067577 13.286114,22.4841029 12.9866648,22.7785691 C12.6864297,23.0738103 12.200709,23.0738103 11.9004739,22.7785691 L3.29347136,15.0837018 C3.27067864,15.0651039 3.23845445,15.072853 3.21723364,15.0519304 C3.06240034,14.899273 2.99480814,14.7001208 3.00030983,14.5001937 C2.99480814,14.3002667 3.06240034,14.1003396 3.21723364,13.9476821 C3.23845445,13.9275344 3.2714646,13.9345086 3.29425732,13.9166857 L11.9004739,6.22026848 C12.200709,5.92657717 12.6864297,5.92657717 12.9866648,6.22026848 C13.286114,6.51628453 13.286114,6.99362977 12.9866648,7.288096 L5.78416545,13.7276073 L24.2140442,13.7276073 C24.6478918,13.7276073 25,14.0739926 25,14.5001937 C25,14.9263948 24.6478918,15.2727801 24.2140442,15.2727801 L5.78416545,15.2727801 Z"></path>
                </g>
              </svg>
            </a>
            <div className="login-title">
              <div onClick={() => history.push('/admin')} className="login-text">{Strings.pay}</div>
            </div>
          </div>
          <div className='payment-body'>
            <div className='payment-info-container'>
              <div className="payment-info-title">
                <div>{Strings.contact}</div>
              </div>
              <div className='payment-info-group'>
                <div className='payment-info-text'>{Strings.phoneNumber}:</div>
                <input onChange={(e) => {if (e.target.value.length <= 11) {setPhone(e.target.value)}}} className='payment-info-input' type='number' value={phone} />
              </div>
              <div className='payment-info-group'>
                <div className='payment-info-text'>{Strings.city}:</div>
                {cityAddress ? <select className="payment-info-input" defaultValue={cityAddress} onChange={(e) => setCityAddress(e.target.value)}>
                  {city.map((item) => (
                    <option key={item} value={item}>{item}</option>
                  ))}
                </select> : null}
              </div>
              <div className='payment-info-group'>
                <div className='payment-info-text'>{Strings.addr}:</div>
                <input type='text' onChange={(e) => setAddress(e.target.value)} className='payment-info-input' value={address} />
              </div>
              <div className="payment-info-title">
                <div>{Strings.order}</div>
              </div>
              <div className='payment-info-group'>
                <div className='payment-info-text'>{Strings.message}:</div>
                <input type='text' onChange={(e) => setMessage(e.target.value)} className='payment-info-input' value={message} />
              </div>
              <div className='payment-info-group'>
                <div className='payment-info-text'>{Strings.totalCostOfGoods}:</div>
                <div className='payment-info-text'>{countTotalMoney(data)}</div>
              </div>
              <div className='payment-info-group'>
                <div className='payment-info-text'>{Strings.shippingFee}:</div>
                <div className='payment-info-text'>{Strings.shopWillContactYou}</div>
              </div>
            </div>
          </div>
          <div className="payment-info-button-container">
            <button onClick={onClickOrder} className="payment-info-button">{Strings.preOrder}</button>
            <div className='payment-info-note'>*Shop sẽ liên hệ bạn để xác nhận đơn hàng!</div>
            <div className='payment-info-note'>*Chỉ thanh toán khi nhận hàng!</div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  )
}
