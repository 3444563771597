const searchType = {
  0: 'search by Text',
  1:' search by Price',
  2: 'search by Type',
}

export const itemTypeDoc = {
  '0': 'Phụ Kiện',
  '1': 'Thời Trang',
  '2': 'Sức Khỏe & Sắc Đẹp',
  '3': 'Thiết Bị',
  '4': 'Dịch Vụ',
  '5': 'Loại Khác',
}

export const itemGuarantee = {
  '0': 'Không Bảo Hành',
  '1': 'Trong Ngày',
  '11': '1 Tuần',
  '111': '1 Tháng',
  '1111': '1 Năm',
}