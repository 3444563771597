import { Navigation } from 'react-minimal-side-navigation'
import { useHistory, useLocation } from 'react-router-dom'
import Icon from 'awesome-react-icons'
import { useSelector } from 'react-redux'
import React, { useState } from 'react'
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css'
import '../styles/NavSlidebar.css'
import NavItem from './NavItem'
import NavBottom from './NavBottom'
import NavTitle from './NavTitle'
import { Strings } from '../configs'

export const NavSidebar = () => {
  const history = useHistory()
  const location = useLocation()
  const user = useSelector(state => state.app.user)
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  return (
    <React.Fragment>
      {/* Sidebar Overlay */}
      <div
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
          isSidebarOpen ? 'block' : 'hidden'
        }`}
      />
      <div>
        <button
          className="btn-menu"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          type="button"
        >
          <Icon name="burger" className="w-6 h-6" />
        </button>
      </div>

      {/* Sidebar */}
      <div
        id='seconddiv'
        className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 ease-out transform translate-x-0 bg-white border-r-2 lg:translate-x-0 lg:static lg:inset-0 ${
          isSidebarOpen ? '' : 'coolclass'
        }`}
      >
        {isSidebarOpen && <>
          <React.Fragment key={user}><NavTitle /></React.Fragment>
          <Navigation
            activeItemId={location.pathname}
            onSelect={({ itemId }) => {
              history.push(itemId)
            }}
            items={NavItem}
          />
          <NavBottom />
        </>}
      </div>
    </React.Fragment>
  )
}
