import { createActions } from 'redux-actions'

const {
  reset,
  updateUser,
  setFirstTimeTutorial,
  setIsShowLoginModal,
} = createActions({
  RESET: () => ({}),
  updateUser: (user) => ({ user }),
  setFirstTimeTutorial: (isFirstTime) => ({ isFirstTime }),
  setIsShowLoginModal: (isShowLoginModal) => ({ isShowLoginModal }),
}, {
  prefix: 'app',
})

export {
  reset,
  updateUser,
  setFirstTimeTutorial,
  setIsShowLoginModal
}
