import React from 'react'
import { useSelector } from 'react-redux'
import './ScrollList.css'
import UnitItem from './UnitItem'
import { Strings } from '../configs'

export default function ScrollList({ title, list, onPressTitle, isAllList }) {
  const user = useSelector(state => state.app.user)

  return (
    <div className='scroll-list-container'>
      <div className='list-title-scroll'>
        <div className='list-title-name'>{title}</div>
        {isAllList ? null : <div role='button'  onClick={onPressTitle} className='list-title-more'>{Strings.seeMore} &gt;</div>}
      </div>
      <div className='div-test-home-page'>    
        <ul className='ul-test-home-page'> 
          {list.map((item) => (
            <UnitItem key={item.uuid} item={item} uid={user ? user.uid : null} />
          ))} 
        </ul>
      </div>
      
      <div className='list-title-more-container'>
        <div role='button' onClick={onPressTitle} className='list-title-more-bottom'>{isAllList ? Strings.seeMore :Strings.seeAll}</div>
      </div>
    </div>
  )
}
