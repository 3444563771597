import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import { Strings, checkAdminUser } from '../../configs'
import '../../styles/Login.css'
import '../../styles/Admin/DeleteData.css'
import PacmanLoader from 'react-spinners/PacmanLoader'
import { deleteItem } from '../../configs/FireBase'
import { showAlert } from '../../common/common'
import { ImageVideoComponent } from '../../common/ImageVideoComponent'
import { search } from '../../services/AlgoliaSearch'

export default function DeleteData() {
  const user = useSelector(state => state.app.user)
  const history = useHistory()
  const [isAdmin, setIsAdmin] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchData, setSearchData] = useState([])

  useEffect(() => {
    firstCheck()
  }, [])

  async function firstCheck() {
    setIsLoading(true)
    const isAd = await checkAdminUser(user)
    setIsAdmin(isAd)
    setIsLoading(false)
  }

  async function onSearch() {
    document.activeElement.blur()
    setIsLoading(true)
    const responseData = await search(null, null, searchText, {
      page: 0,
      hitsPerPage: 100,
    })
    const data = responseData.hits
    setSearchData(data)
    setIsLoading(false)
  }

  async function onDelete(item) {
    showAlert(Strings.doYouWantToDelete, '', 'Yes', 'No', async () => {
      setIsLoading(true)
      await deleteItem(item)
      await onSearch()
      setIsLoading(false)
    })
  }

  if (isAdmin) {return (
    <LoadingOverlay
      active={isLoading}
      spinner={<PacmanLoader />}
    >
      <div className='full-body'>
        <div className="app-container">
          <div className="navigation-bar">
            <a onClick={() => history.goBack()}>
              <svg viewBox="0 0 22 17" className="back-button">
                <g stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(-3, -6)">
                  <path d="M5.78416545,15.2727801 L12.9866648,21.7122915 C13.286114,22.0067577 13.286114,22.4841029 12.9866648,22.7785691 C12.6864297,23.0738103 12.200709,23.0738103 11.9004739,22.7785691 L3.29347136,15.0837018 C3.27067864,15.0651039 3.23845445,15.072853 3.21723364,15.0519304 C3.06240034,14.899273 2.99480814,14.7001208 3.00030983,14.5001937 C2.99480814,14.3002667 3.06240034,14.1003396 3.21723364,13.9476821 C3.23845445,13.9275344 3.2714646,13.9345086 3.29425732,13.9166857 L11.9004739,6.22026848 C12.200709,5.92657717 12.6864297,5.92657717 12.9866648,6.22026848 C13.286114,6.51628453 13.286114,6.99362977 12.9866648,7.288096 L5.78416545,13.7276073 L24.2140442,13.7276073 C24.6478918,13.7276073 25,14.0739926 25,14.5001937 C25,14.9263948 24.6478918,15.2727801 24.2140442,15.2727801 L5.78416545,15.2727801 Z"></path>
                </g>
              </svg>
            </a>
            <div className="login-title">
              <div onClick={() => history.push('/admin')} className="login-text">{Strings.admin}</div>
            </div>
          </div>
          <div className='delete-data-container'>
            <div className='search-bar'>
              <div style={{ marginTop: 20 }} />
              <input
                onSubmit={onSearch}
                placeholder='Input Name'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className='search-box'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSearch()
                    document.activeElement.blur()
                  }
                }}
              />
              <button onClick={onSearch} className='admin-button'>{Strings.search}</button>
            </div>
            <div className='result-bar'>
              {searchData.map((item) => (
                <DeleteItemComponent key={item.uuid} item={item} onDelete={onDelete} />
              ))}
            </div>
          </div>
        </div>
        <div className='admin-footer' />
      </div>
    </LoadingOverlay>
    
  )}
  else {
    return null
  }
}

function DeleteItemComponent({ item, onDelete}) {
  const history = useHistory()
  return (
    <div key={item.uuid} className='result-item'>
      <ImageVideoComponent className='result-image' item={item} />
      <div role='button' onClick={() => history.push(`/adminUpdate?id=${item.uuid}`)} className='result-text'>{item.name}</div>
      <div onClick={() => onDelete(item)} role='button' className='result-delete-button'>
        <svg className='result-delete-button-icon' version="1.1" id="Layer_1" x="0px" y="0px" width="122.879px" height="122.879px" viewBox="0 0 122.879 122.879" enableBackground="new 0 0 122.879 122.879">
          <g>
            <path fillRule="evenodd" clipRule="evenodd" fill="#FF4141" d="M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z" />
          </g>
        </svg>
      </div>
    </div>
  )
}