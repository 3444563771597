import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import DetailScreen from './DetailScreen'
import Home from './Home'
import ShoppingCart from './ShoppingCart'
import AnswerQuestion from './AnswerQuestion'
import Login from './Login'
import AddData from '../components/Admin/AddData'
import DeleteData from '../components/Admin/DeleteData'
import UpdateData from '../components/Admin/UpdateData'
import Admin from './Admin'
import SearchScreen from './SearchScreen'
import UserDetail from './UserDetail'
import PaymentScreen from '../components/ShoppingCart/PaymentScreen'
import Policy from './Policy'
import PrivacyPolicy from './PrivacyPolicy'
import DownloadApp from './DownloadApp'

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/search">
          <SearchScreen />
        </Route>
        <Route path="/policy">
          <Policy />
        </Route>
        <Route path="/PrivacyPolicy">
          <PrivacyPolicy />
        </Route>
        <Route path="/app">
          <DownloadApp />
        </Route>
        <Route path="/detail">
          <DetailScreen />
        </Route>
        <Route path="/userDetail">
          <UserDetail />
        </Route>
        <Route path="/admin">
          <Admin />
        </Route>
        <Route path="/adminAdd">
          <AddData />
        </Route>
        <Route path="/adminDelete">
          <DeleteData />
        </Route>
        <Route path="/adminUpdate">
          <UpdateData />
        </Route>
        <Route path="/shopping">
          <ShoppingCart />
        </Route>
        <Route path="/payment">
          <PaymentScreen />
        </Route>
        <Route path="/question">
          <AnswerQuestion />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
