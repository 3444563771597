import Strings from './Strings'
import { getAdminUser } from './FireBase'
import { isMobile } from 'react-device-detect'

async function checkAdminUser(user) {
  const admin = await getAdminUser()
  return admin.includes(user.uid)
}

var priceFormatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'VND' })
const { innerWidth } = window
const unitItemWidth = isMobile ? (innerWidth / 2) - 15 : 190

export { Strings, checkAdminUser, priceFormatter, unitItemWidth }