import React from 'react'
import '../../styles/Home/Footer.css'
import { Strings } from '../../configs'

export default function Footer() {
  return (
    <div id='footer'>
      <div className='footer-title'>
        <div>{Strings.whiteBearProduction}</div>
      </div>
      <div className='footer-text'>
        <div>{Strings.address}</div>
      </div>
    </div>
  )
}