import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import '../../styles/Home/HomePage.css'
import ScrollList from '../../common/ScrollList'
import { searchItemByPrice, searchItem } from '../../configs/FireBase'
import LoadingOverlay from 'react-loading-overlay'
import { PropagateLoader } from 'react-spinners'

export default function HomePage() {
  const history = useHistory()
  const [list10KItem, setList10KItem] = useState([])
  const [list50KItem, setList50KItem] = useState([])
  const [list100KItem, setList100KItem] = useState([])
  const [listNewItem, setListNewItem] = useState([])
  const [listAllItem, setListAllItem] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [lastVisible, setLastVisible] = useState(null)
  useEffect(() => {
    getList10KItem()
    getList50KItem()
    getList100KItem()
    getNewItemList()
    getAllItemList()
    setTimeout(() => {
      setIsLoading(true)
    }, 3000)
  }, [])
  async function getList10KItem() {
    const [data] = await searchItemByPrice(10000, 10)
    setList10KItem(data)
  }
  async function getList50KItem() {
    const [data] = await searchItemByPrice(50000, 10)
    setList50KItem(data)
  }
  async function getList100KItem() {
    const [data] = await searchItemByPrice(100000, 10)
    setList100KItem(data)
  }
  async function getNewItemList() {
    const [data] = await searchItem(20, null, 'desc')
    setListNewItem(data)
  }
  async function getAllItemList() {
    let resultData
    let lastElement
    [resultData, lastElement] = await searchItem(50, null, null, lastVisible)
    setLastVisible(lastElement)
    setListAllItem([...listAllItem, ...resultData])
  }

  if (listNewItem.length === 0) {
    return (
      <div className='home-page'>
        <LoadingOverlay
          active
          spinner={<PropagateLoader />}
        >
          <div className='home-place-holder-container' />
        </LoadingOverlay>
      </div>
    )
  }

  return (
    <div className='home-page'>
      <ScrollList
        onPressTitle={() => history.push('/search?searchText=NEW&searchType=3&page=0')}
        title='NEW'
        list={listNewItem}
      />
      <ScrollList
        onPressTitle={() => history.push('/search?searchText=10000&searchType=1&page=0')}
        title='Đồng Giá 10k'
        list={list10KItem}
      />
      <ScrollList
        onPressTitle={() => history.push('/search?searchText=50000&searchType=1&page=0')}
        title='Đồng Giá 50k'
        list={list50KItem}
      />
      <ScrollList
        onPressTitle={() => history.push('/search?searchText=100000&searchType=1&page=0')}
        title='Đồng Giá 100k'
        list={list100KItem}
      />
      <ScrollList
        onPressTitle={() => getAllItemList()}
        title='Tất cả'
        list={listAllItem}
        isAllList={true}
      />
      <div className="home-page-bottom" />
    </div>
  )
}
