import React from 'react'
import DetailPage from '../components/Detail/DetailPage'
import Header from '../common/Header'

export default function DetailScreen() {
  return (
    <Header>
      <DetailPage />
    </Header>
  )
}