import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import LoadingOverlay from 'react-loading-overlay'
import '../../styles/Search/SearchPage.css'
import { Strings } from '../../configs'
import { itemTypeDoc } from '../../configs/ArgumentDoc'
import UnitItem from '../../common/UnitItem'
import { search } from '../../services/AlgoliaSearch'
import SearchPagination from './SearchPagination'

const hitsPerPage = 50
let page = 0
let sortPrice = null
export default function SearchPage(props) {
  const location = useLocation()
  const [searchData, setSearchData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchTitle, setSearchTitle] = useState('')
  const values = queryString.parse(location.search)
  const listInnerRef = useRef()
  
  useEffect(() => {
    onResetPage()
  }, [location])

  async function onResetPage() {
    page = 0
    window.scrollTo(0, 0)
    setSearchData([])
    onSearch(true)
  }

  async function onSelectSort() {
    page = 0
    window.scrollTo(0, 0)
    setSearchData([])
    onSearch(true)
  }

  async function searchQuery(price, searchAttribute) {
    const responseData = await search(searchAttribute, price, values.searchText !== 'NEW' ? values.searchText : '', {
      page: page,
      hitsPerPage: hitsPerPage,
    })
    const resultData = responseData.hits
    page += 1
    return resultData
  }

  async function onSearch(isReset) {
    if (isReset){setIsLoading(true)}
    let resultData
    switch (values.searchType) {
      case '0': // search by Text
        setSearchTitle(values.searchText)
        resultData = await searchQuery(sortPrice)
        break
      case '1': // search by Price
        setSearchTitle(`${Strings.samePrice} ${parseInt(values.searchText)/1000}k`)
        resultData = await searchQuery(sortPrice, 'price')
        break
      case '2': // search by Type
        setSearchTitle(itemTypeDoc[values.searchText])
        resultData = await searchQuery(sortPrice, 'itemType')
        break
      case '3': // search by Date
        setSearchTitle(Strings.newProduct)
        resultData = await searchQuery(sortPrice)
        break
      default:
        break
    }
    if (isReset) {
      setSearchData(resultData)
    } else {
      setSearchData([...searchData, ...resultData])
    }
    setIsLoading(false)
    window.scrollTo(0, 0)
  }

  function onSelectSortPrice(value) {
    sortPrice = value
    onSelectSort()
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight === scrollHeight) {
        onSearch()
      }
    }
  }

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text={Strings.search}
    >
      <div className='search-page' onScroll={() => onScroll()} ref={listInnerRef}>
        <h1 className="search-result-header">
          <svg viewBox="0 0 18 24" className="svg-icon-icon-hint-bulb">
            <g transform="translate(-355 -149)"><g transform="translate(355 149)"><g fillRule="nonzero" transform="translate(5.4 19.155556)"><path d="m1.08489412 1.77777778h5.1879153c.51164401 0 .92641344-.39796911.92641344-.88888889s-.41476943-.88888889-.92641344-.88888889h-5.1879153c-.51164402 0-.92641345.39796911-.92641345.88888889s.41476943.88888889.92641345.88888889z"></path><g transform="translate(1.9 2.666667)"><path d="m .75 1.77777778h2.1c.41421356 0 .75-.39796911.75-.88888889s-.33578644-.88888889-.75-.88888889h-2.1c-.41421356 0-.75.39796911-.75.88888889s.33578644.88888889.75.88888889z"></path></g></g><path d="m8.1 8.77777718v4.66666782c0 .4295545.40294373.7777772.9.7777772s.9-.3482227.9-.7777772v-4.66666782c0-.42955447-.40294373-.77777718-.9-.77777718s-.9.34822271-.9.77777718z" fillRule="nonzero"></path><path d="m8.1 5.33333333v.88889432c0 .49091978.40294373.88888889.9.88888889s.9-.39796911.9-.88888889v-.88889432c0-.49091977-.40294373-.88888889-.9-.88888889s-.9.39796912-.9.88888889z" fillRule="nonzero"></path><path d="m8.80092773 0c-4.86181776 0-8.80092773 3.97866667-8.80092773 8.88888889 0 1.69422221.47617651 3.26933331 1.295126 4.61333331l2.50316913 3.9768889c.30201078.4782222.84303623.7697778 1.42482388.7697778h7.17785139c.7077799 0 1.3618277-.368 1.7027479-.9617778l2.3252977-4.0213333c.7411308-1.2888889 1.1728395-2.7786667 1.1728395-4.37688891 0-4.91022222-3.9409628-8.88888889-8.80092777-8.88888889m0 1.77777778c3.82979317 0 6.94810087 3.18933333 6.94810087 7.11111111 0 1.24444441-.3168334 2.43022221-.9393833 3.51466671l-2.3252977 4.0213333c-.0166754.0284444-.0481735.0462222-.0833772.0462222h-7.07224026l-2.43461454-3.8648889c-.68184029-1.12-1.04128871-2.4053333-1.04128871-3.71733331 0-3.92177778 3.11645483-7.11111111 6.94810084-7.11111111"></path></g></g>
          </svg>
          <span className="search-result-header__text">{Strings.searchResultFor}: </span>
          <span className="search-result-header__text-highlight">{searchTitle}</span>
        </h1>
        <div className="search-cart-page-product-header">
          <div className="search-cart-page-product-header__product">{Strings.sortBy}: </div>
          <select defaultValue={sortPrice} className="search-type-input" onChange={(e) => onSelectSortPrice(e.target.value)}>
            <option value="">{Strings.newest}</option>
            <option value="asc">{Strings.pri}: {Strings.lowToHigh}</option>
            <option value="desc">{Strings.pri}: {Strings.highToLow}</option>
          </select>
        </div>
        <div className='search-content'>
          <ListData data={searchData} />
        </div>
        <div className="search-page-bottom" />
      </div>
    </LoadingOverlay>
  )
}

function ListData({ data }) {
  const user = useSelector(state => state.app.user)

  return (
    data.map((item) => (
      <UnitItem key={item.uuid} item={item} uid={user ? user.uid : null} />
    ))
  )
}