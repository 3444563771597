import React from 'react'
import '../styles/Home/HomePage.css'
import beargif from '../assets/whitebeargif.gif'
import '../styles/ConfirmAlert.css'
import { confirmAlert } from 'react-confirm-alert'

export function getSubstrings(text) {
  return text.toLowerCase().replace('.','').split(' ')
}

export function checkArrayContainKey(array, uuid) {
  return array.some((item) => item.uuid === uuid)
}

export function LoadingIcon() {
  return (
    <img className='gif-image' src={beargif} />
  )
}

export function showAlert(title, message, button, cancelButton, onClick) {
  confirmAlert({
    title: title,
    message,
    buttons: cancelButton ? [
      {
        label: button,
        onClick: onClick ? onClick : () => {},
      },
      {
        label: cancelButton,
      }
    ] : [
      {
        label: button,
        onClick: onClick ? onClick : () => {},
      }
    ],
  })
}

export function countShippingNum(data) {
  let total = 0
  data.map((item) => {
    total += item.cartNum
  })
  return total
}
