import React, { useState, useEffect } from 'react'
import { useWindowDimensions } from '../../hooks'
import { isMobile } from 'react-device-detect'
import { ImageVideoComponentDetail, ImageBellowVideoDetailScreen } from '../../common/ImageVideoComponent'

export default function DetailImageList({ item }) {
  const windowData = useWindowDimensions()
  const [currentImage, setCurrentImage] = useState(item.image[0])
  const imageWidth = isMobile ? windowData.width : 320

  useEffect(() => {
    if (item.image.constructor === Array) {
      setCurrentImage(item.image[0])
    } else {
      setCurrentImage(item.image)
    }
  }, [])

  return (
    <div className='product-image'>
      <ImageVideoComponentDetail className='detail-image' image={currentImage} />
      <ImageBellowVideoDetailScreen onChangeImage={setCurrentImage} images={item.image} />
    </div>
  )
}