import React from 'react'
import '../../styles/Home/SearchBar.css'

export default function SearchShortBar({ itemTypeDoc }) {
  return(
    <div className="shortBar">
      <div className='shortBarContent'>
        {Object.keys(itemTypeDoc).map((i) => (
          <a key={i} className='shortBarItem' href={`/search?searchText=${i}&searchType=2&page=0`}>{itemTypeDoc[i]}</a>
        ))}
      </div>
    </div>
  )
}