import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/database'
import 'firebase/analytics'
import { getSubstrings, checkArrayContainKey } from '../common/common'
import moment from 'moment'

const maxShopItem = 10
export const maxItemNum = 100
const firebaseConfig = {
  apiKey: 'AIzaSyDOO00gqsrTGspPBania32fC2NJhmmZUzc',
  authDomain: 'whitebear-a05e2.firebaseapp.com',
  databaseURL: 'https://whitebear-a05e2-default-rtdb.firebaseio.com',
  projectId: 'whitebear-a05e2',
  storageBucket: 'whitebear-a05e2.appspot.com',
  messagingSenderId: '371180356881',
  appId: '1:371180356881:web:6769d1d8521930a04838b0',
  measurementId: 'G-3MQ3Y7ZBZ3'
}

firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()
export const storage = firebase.storage()
export const realDatabase = firebase.database()
export const analytics = firebase.analytics()
const db = firebase.firestore()
db.settings({
  timestampsInSnapshots: true
})

const googleProvider = new firebase.auth.GoogleAuthProvider()
googleProvider.addScope('email')
googleProvider.addScope('phone')
const facebookProvider = new firebase.auth.FacebookAuthProvider()
export const signInWithGoogle = (callback) => {
  auth.signInWithRedirect(googleProvider)
}

export const signInWithFacebook = (callback) => {
  auth.signInWithRedirect(facebookProvider)
}

export const getSignData = (callback) => {
  auth.getRedirectResult().then((res) => {
    console.log(res)
    if (callback) {callback({ ...res.user, accessToken: res.accessToken || res.credential.accessToken })}
  }).catch((error) => {
    if (callback) {callback(false)}
  })
}

export const logOut = (callback) => {
  auth.signOut().then(() => {
    if (callback) {callback()}
  })
}

export async function getAdminUser() {
  const snapshot = await db.collection('adminUsers').get()
  let data = []
  snapshot.forEach((doc) => {
    data.push(doc.id)
  })
  return data
}
export async function getUser() {
  const snapshot = await db.collection('users').get()
  let data = []
  snapshot.forEach((doc) => {
    data.push(doc.id)
  })
  return data
}
export async function getUserInformation(id) {
  const data = await realDatabase.ref('users').child(id).get()
  return data.val()
}
export async function addUser(user) {
  const data = await realDatabase.ref('users').child(user.uid).get()
  if (data.exists()) {
    await  updateUser(user)
  } else {
    await  realDatabase.ref('users').update({ [user.uid]: user })
  }
}
export async function updateUser(user) {
  await  realDatabase.ref('users').child(user.uid).update({ ...user })
}

export async function searchItemByPrice(price=10000, limit=20, orderByDate='desc', lastElement) {
  try {
    const snapshot = lastElement ? await db.collection('items').where('price', '==', price).orderBy('uuid', orderByDate).startAfter(lastElement).limit(limit).get()
      : await db.collection('items').where('price', '==', price).orderBy('uuid', orderByDate).limit(limit).get()
    const lastVisible = snapshot.docs[snapshot.docs.length-1]
    let data = []
    snapshot.forEach((doc) => {
      data.push(doc.data())
    })
    return [data, lastVisible]
  } catch (error) {
    return [[], null]
  }
  
}
export async function searchItem(limit=20, orderByPrice, orderByDate, lastElement) {
  try {
    let snapshot
    if (orderByPrice && orderByDate) {
      snapshot = lastElement ? await db.collection('items').limit(limit).orderBy('price', orderByPrice).orderBy('uuid', orderByDate).startAfter(lastElement).get()
        : await db.collection('items').limit(limit).orderBy('price', orderByPrice).orderBy('uuid', orderByDate).get()
    } else {
      if (orderByPrice) {
        snapshot = lastElement ? await db.collection('items').limit(limit).orderBy('price', orderByPrice).startAfter(lastElement).get()
          : await db.collection('items').limit(limit).orderBy('price', orderByPrice).get()
      } else {
        if (orderByDate) {
          snapshot = lastElement ? await db.collection('items').limit(limit).orderBy('uuid', orderByDate).startAfter(lastElement).get()
            : await db.collection('items').limit(limit).orderBy('uuid', orderByDate).get()
        } else {
          snapshot = lastElement ? await db.collection('items').limit(limit).startAfter(lastElement).get()
            : await db.collection('items').limit(limit).get()
        }
      }
    }
    const lastVisible = snapshot.docs[snapshot.docs.length-1]
    let data = []
    snapshot.forEach((doc) => {
      data.push(doc.data())
    })
    return [data, lastVisible]
  } catch (error) {
    return [[], null]
  }
}
export async function searchItemByName(name, limit=50, orderByPrice, orderByDate, lastElement) {
  try {
    const listSearchName = getSubstrings(name)
    let data = []
    let snapshot
    if (orderByPrice && orderByDate) {
      snapshot = lastElement ? await db.collection('items').where('listSubName', 'array-contains-any', listSearchName).limit(limit).orderBy('price', orderByPrice).orderBy('uuid', orderByDate).startAfter(lastElement).get()
        : await db.collection('items').where('listSubName', 'array-contains-any', listSearchName).limit(limit).orderBy('price', orderByPrice).orderBy('uuid', orderByDate).get()
    } else {
      if (orderByPrice) {
        snapshot = lastElement ? await db.collection('items').where('listSubName', 'array-contains-any', listSearchName).limit(limit).orderBy('price', orderByPrice).startAfter(lastElement).get()
          : await db.collection('items').where('listSubName', 'array-contains-any', listSearchName).limit(limit).orderBy('price', orderByPrice).get()
      } else {
        if (orderByDate) {
          snapshot = lastElement ? await db.collection('items').where('listSubName', 'array-contains-any', listSearchName).limit(limit).orderBy('uuid', orderByDate).startAfter(lastElement).get()
            : await db.collection('items').where('listSubName', 'array-contains-any', listSearchName).limit(limit).orderBy('uuid', orderByDate).get()
        } else {
          snapshot = lastElement ? await db.collection('items').where('listSubName', 'array-contains-any', listSearchName).limit(limit).limit(limit).startAfter(lastElement).get()
            : await db.collection('items').where('listSubName', 'array-contains-any', listSearchName).limit(limit).limit(limit).get()
        }
      }
    }
    const lastVisible = snapshot.docs[snapshot.docs.length-1]
    snapshot.forEach((doc) => {
      if (!checkArrayContainKey(data, doc.data().uuid)) {data.push(doc.data())}
    })
    return [data, lastVisible]
  } catch (error) {
    return [[], null]
  } 
}
export async function searchItemByType(type, limit=50, orderByPrice, orderByDate, lastElement) {
  try {
    let data = []
    let snapshot
    if (orderByPrice && orderByDate) {
      snapshot = lastElement ? await db.collection('items').where('itemType', '==', `${type}`).limit(limit).orderBy('price', orderByPrice).orderBy('uuid', orderByDate).startAfter(lastElement).get()
        : await db.collection('items').where('itemType', '==', `${type}`).limit(limit).orderBy('price', orderByPrice).orderBy('uuid', orderByDate).get()
    } else {
      if (orderByPrice) {
        snapshot = lastElement ? await db.collection('items').where('itemType', '==', `${type}`).limit(limit).orderBy('price', orderByPrice).startAfter(lastElement).get()
          : await db.collection('items').where('itemType', '==', `${type}`).limit(limit).orderBy('price', orderByPrice).get()
      } else {
        if (orderByDate) {
          snapshot = lastElement ? await db.collection('items').where('itemType', '==', `${type}`).limit(limit).orderBy('uuid', orderByDate).startAfter(lastElement).get()
            : await db.collection('items').where('itemType', '==', `${type}`).limit(limit).orderBy('uuid', orderByDate).get()
        } else {
          snapshot = lastElement ? await db.collection('items').where('itemType', '==', `${type}`).limit(limit).limit(limit).startAfter(lastElement).get()
            : await db.collection('items').where('itemType', '==', `${type}`).limit(limit).limit(limit).get()
        }
      }
    }
    const lastVisible = snapshot.docs[snapshot.docs.length-1]
    snapshot.forEach((doc) => {
      if (!checkArrayContainKey(data, doc.data().uuid)) {data.push(doc.data())}
    })
    return [data, lastVisible]
  } catch (error) {
    return [[], null]
  }
}
export async function getItemByID(id) {
  let data = []
  const snapshot = await db.collection('items').where('uuid', '==', id).get()
  snapshot.forEach((doc) => {
    if (!checkArrayContainKey(data, doc.data().uuid)) {data.push(doc.data())}
  })
  return data[0]
}

export async function setItem(item) {
  try {
    await db.collection('items').doc(item.uuid).set(item)
    return true
  } catch (error) {
    return false
  }
  
}
export async function updateItem(item) {
  await db.collection('items').doc(item.uuid).update(item)
}
export async function deleteItem(item) {
  if (item.image.constructor === Array) {
    const promises = item.image.map(async i => {
      const numFruit = await storage.refFromURL(i.url).delete()
      return numFruit
    })
    await Promise.all(promises)
  } else {
    await storage.refFromURL(item.image).delete()
  }
  await db.collection('items').doc(item.uuid).delete()
}

export async function deleteImage(listImage) {
  if (listImage.constructor === Array) {
    const promises = listImage.map(async i => {
      const numFruit = await storage.refFromURL(i.url).delete()
      return numFruit
    })
    await Promise.all(promises)
  } else {
    await storage.refFromURL(listImage).delete()
  }
}

export function uploadFileToStorage() {
  storage
    .ref('../assets/whitebearlazy.png')
    .getDownloadURL()
    .then( url => {
      console.log( 'Got download url: ', url )
    })
}

// Cart API
async function getItemIDFromCart(uid) {
  const data = await realDatabase.ref('users').child(uid).child('cart').get()
  if (data.val()) {
    return data.val()
  }
  return []
}
export async function getItemFromCart(uid) {
  try {
    const data = await getItemIDFromCart(uid)
    const snapshot = await db.collection('items').where('uuid', 'in', Object.keys(data)).limit(maxShopItem).get()
    let data2 = []
    snapshot.forEach((doc) => {
      data2.push({ ...doc.data(), cartNum: data[doc.data().uuid].number })
    })
    return data2
  } catch (error) {
    return []
  }
}
export async function addItemToCart(uid, id, number, inventory) {
  try {
    const data = await getItemIDFromCart(uid)
    const maxItemNumberToRent = maxItemNum <= inventory ? maxItemNum : inventory
    const dataList = Object.keys(data)
    if (dataList && dataList.length >= maxShopItem) {return false}
    if (!dataList || !dataList.includes(id)) {await realDatabase.ref('users').child(uid).child('cart').update({ [id]: { number } })}
    else {
      if (data[id].number + number <= maxItemNumberToRent) {
        await realDatabase.ref('users').child(uid).child('cart').child(id).set({ number: data[id].number + number })
        return true
      }
      return false
    }
    return true
  } catch (error) {
    return false
  }
}
export async function deleteItemFromCart(uid, id) {
  await realDatabase.ref('users').child(uid).child('cart').child(id).remove()
}
export async function deleteCart(uid) {
  await realDatabase.ref('users').child(uid).child('cart').remove()
}
export async function updateItemNumberFromCart(uid, id, number) {
  try {
    if (number <= maxItemNum && number > 0) {
      await realDatabase.ref('users').child(uid).child('cart').child(id).set({ number: number })
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

// Payment
export async function addToPayment(uid, data, address, cityAddress, phone, message, email, displayName, photoURL, price) {
  const dataID = moment().format('YYYYMMDDHHmmss')
  const date = moment().format('DD-MM-YYYY HH:mm')
  await deleteCart(uid)
  await db.collection('checkout').doc(`${dataID}-${uid}`).set({ data, address, cityAddress, phone, message, email, uid, displayName, id: `${dataID}-${uid}`, photoURL, price, createDate: date })
}
export async function getShippingItem(uid) {
  try {
    let data = []
    const snapshot = await db.collection('ship').where('uid', '==', uid).limit(20).get()
    snapshot.forEach((doc) => {
      data.push(doc.data())
    })
    console.log(data)
    return data
  } catch (error) {
    return []
  }
}
export async function getWaitingItem(uid) {
  try {
    let data = []
    const snapshot = await db.collection('checkout').where('uid', '==', uid).limit(20).get()
    snapshot.forEach((doc) => {
      data.push(doc.data())
    })
    console.log(data)
    return data
  } catch (error) {
    return []
  }
}

export async function cancelWaitingItem(item) {
  await db.collection('checkout').doc(item.id).delete()
}

export async function deleteUser(uid, logout) {
  try {
    await realDatabase.ref('users').child(uid).remove()
    await realDatabase.ref('notification').child(uid).remove()
    auth.currentUser.delete()
    logout()
  } catch (error) {
    console.log(error)
  }
}
