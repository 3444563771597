import React from 'react'
import '../styles/Login.css'
import bearImage from '../assets/whitebearlazy.png'
import { useHistory } from 'react-router-dom'

export default function DownloadApp() {
  const history = useHistory()
  const url = `${window.navigator.platform.toString()}`.includes('Linux') ? 'market://details?id=com.whitebearapp' : 'https://play.google.com/store/apps/details?id=com.whitebearapp'
  return (
    <div id="app">
      <div className="app-container">
        <div className="navigation-bar">
          <a onClick={() => history.goBack()}>
            <svg viewBox="0 0 22 17" className="back-button">
              <g stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(-3, -6)">
                <path d="M5.78416545,15.2727801 L12.9866648,21.7122915 C13.286114,22.0067577 13.286114,22.4841029 12.9866648,22.7785691 C12.6864297,23.0738103 12.200709,23.0738103 11.9004739,22.7785691 L3.29347136,15.0837018 C3.27067864,15.0651039 3.23845445,15.072853 3.21723364,15.0519304 C3.06240034,14.899273 2.99480814,14.7001208 3.00030983,14.5001937 C2.99480814,14.3002667 3.06240034,14.1003396 3.21723364,13.9476821 C3.23845445,13.9275344 3.2714646,13.9345086 3.29425732,13.9166857 L11.9004739,6.22026848 C12.200709,5.92657717 12.6864297,5.92657717 12.9866648,6.22026848 C13.286114,6.51628453 13.286114,6.99362977 12.9866648,7.288096 L5.78416545,13.7276073 L24.2140442,13.7276073 C24.6478918,13.7276073 25,14.0739926 25,14.5001937 C25,14.9263948 24.6478918,15.2727801 24.2140442,15.2727801 L5.78416545,15.2727801 Z"></path>
              </g>
            </svg>
          </a>
          <div className="login-title">
            <div className="login-text">Tải app</div>
          </div>
        </div>
        <div>
          <div className="form-container">
            <div className="logo-container">
              <img className="logo" src={bearImage} />
            </div>
            <div>
              <a href={url} target="_blank" rel="noreferrer" className="fb-container">
                <div style={{ backgroundColor: 'white' }} className="fb-icon-content">
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" width="1200px" height="1200px" viewBox="0 0 1200 1200" enableBackground="new 0 0 1200 1200">
                    <g>
                      <g>
                        <path fill="#77B834" d="M400.582,333.356h394.7v324.791c0,0-6.019,28.918-28.918,28.918c-22.907,0-43.984,0-43.984,0v104.245    c0,0-1.823,43.984-44.001,43.984c-42.169,0-43.992-43.984-43.992-43.984V690.067h-72.292v104.254c0,0,0.594,39.761-45.212,39.16    c0,0-42.17-0.586-42.17-42.17c0-41.584,0-103.049,0-103.049h-42.78c0,0-31.351,1.805-31.351-31.343    C400.582,623.77,400.582,333.356,400.582,333.356z"/>
                        <path fill="#77B834" d="M821.491,376.128c0,0,0.017-42.779,42.789-42.779c42.78,0,43.081,36.751,43.081,43.081    c0,6.328,0,186.494,0,186.494s4.222,40.221-40.221,40.221c-44.427,0-45.648-29.37-45.648-42.028S821.491,376.128,821.491,376.128z    "/>
                        <path fill="#77B834" d="M286.096,376.128c0,0,0-42.779,42.788-42.779c42.78,0,43.081,36.751,43.081,43.081    c0,6.328,0,186.494,0,186.494s4.222,40.221-40.23,40.221c-44.435,0-45.639-29.37-45.639-42.028S286.096,376.128,286.096,376.128z"/>
                        <path fill="#77B834" d="M693.445,166.008l34.135-50.53c5.768-8.159-1.455-10.099-1.455-10.099    c-4.807-3.837-8.653,2.893-8.653,2.893l-35.406,52.662c-24.797-10.141-52.427-15.876-81.705-16.161    c-30.24-0.3-58.974,5.317-84.816,15.684c-10.634-15.34-38.148-54.877-40.096-56.031c-2.391-1.446-5.777,1.455-5.777,1.455    c-6.237,4.808-3.36,7.207-3.36,7.207l36.016,53.172c-55.153,26.72-94.564,76.296-102.188,136.976h395.018    C787.775,242.371,748.765,192.661,693.445,166.008z"/>
                        <path fill="#FFFFFF" d="M704.932,228.209c0,12.148-9.84,22.004-21.988,22.004c-12.147,0-21.996-9.856-21.996-22.004    c0-12.139,9.849-21.988,21.996-21.988C695.092,206.221,704.932,216.069,704.932,228.209z"/>
                        <path fill="#FFFFFF" d="M535.861,228.209c0,12.148-9.849,22.004-21.988,22.004c-12.147,0-22.004-9.856-22.004-22.004    c0-12.139,9.857-21.988,22.004-21.988C526.012,206.221,535.861,216.069,535.861,228.209z"/>
                      </g>
                      <g>
                        <path fill="#666264" d="M153.275,1095.979v-32.346h-42.086c0,0-33.909-12.867-31.953-42.872c0,0,1.563-32.338,28.458-39.737    h70.912v113.784h31.945V948.284h-97.023c0,0-61.18,3.52-63.529,73.663c0,0,1.178,63.514,68.211,73.638L153.275,1095.979z"/>
                        <path fill="#666264" d="M1005.528,1065.12v29.612l84.097-1.162c0,0,61.072-4.281,60.336-72.877c0,0,3.662-67.016-59.96-72.869    h-83.745v31.569h74.975c0,0,39.486,3.495,35.465,44.026c0,0,4.381,40.523-37.296,41.308L1005.528,1065.12z"/>
                        <path fill="#666264" d="M423.523,1063.389v29.613l84.089-1.162c0,0,61.072-4.289,60.336-72.869c0,0,3.662-67.033-59.969-72.869    H424.25v31.552h74.958c0,0,39.494,3.528,35.465,44.042c0,0,4.389,40.523-37.295,41.3L423.523,1063.389z"/>
                        <path fill="#666264" d="M274.458,1094.808h-29.612l1.163-89.632c0,0,4.288-65.068,72.869-64.299c0,0,67.025-3.896,72.869,63.906    v89.247h-31.552v-79.883c0,0-3.511-42.094-44.033-37.797c0,0-40.523-4.682-41.308,39.744L274.458,1094.808z"/>
                        <path fill="#666264" d="M601.33,979.226v-31.451h82.625c0,0,45.438,0.635,45.438,52.118c0,0,1.279,26.995-22.882,43.849    c0,0-2.559,1.279-0.777,3.394c0.209,0.226,0.46,0.477,0.777,0.728c3.185,2.541,18.434,13.033,18.434,47.353h-31.778    c0,0-1.907-40.347-34.01-40.347h-58.781V1019.9h78.813c0,0,18.434-0.945,17.791-21.611c0,0,2.224-19.38-21.603-19.38    L601.33,979.226z"/>
                        <path fill="#666264" d="M834.275,943.018c-42.303,0-76.589,34.211-76.589,76.405c0,42.228,34.286,76.43,76.589,76.43    c42.295,0,76.581-34.202,76.581-76.43C910.855,977.228,876.569,943.018,834.275,943.018z M834.275,1063.449    c-24.362,0-44.117-19.706-44.117-44.026c0-24.295,19.755-44.009,44.117-44.009c24.353,0,44.093,19.713,44.093,44.009    C878.367,1043.743,858.628,1063.449,834.275,1063.449z"/>
                        <rect x="941.68" y="948.1" fill="#666264" width="33.366" height="147.435"/>
                      </g>
                    </g>
                  </svg>
                </div>
                <span className="fb-text">Cho Android</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}