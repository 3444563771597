export default {
  shopName: 'White Bear',
  address: 'Shop Phụ Kiện in Quảng Nam',
  addr: 'Địa Chỉ',
  registration: 'Đăng Ký',
  consultationScheduling: 'Đặt Lịch Tư Vấn',
  requestAQuote: 'Yêu Cầu Báo Giá',
  judicialRecord: 'Lý Lịch Tư Pháp',
  trademarkLookup: 'Tra Cứu Nhãn Hiệu',
  priceList: 'Bảng Giá',
  price: 'Đơn Giá',
  totalPrice: 'Tổng Tiền',
  answerQuestion: 'Hỏi Đáp',
  pleaseLogin: 'Vui Lòng Đăng Nhập',
  youMustLoginToAddToCart: 'Bạn phải đăng nhập để mua hàng!',
  logOut: 'Đăng Xuất',
  shoppingCart: 'Giỏ Hàng',
  whiteBearProduction: 'White Bear production',
  downloadApp: 'Tải ứng dụng',
  login: 'Đăng Nhập',
  cancel: 'Hủy',
  addToCartSuccess: 'Thêm Vào Giỏ Hàng Thành Công',
  reachMaximumNumberItem: 'Quá Số Lượng Cho Phép!',
  emailPhoneUsername: 'Email/Số điện thoại/Tên đăng nhập',
  password: 'Mật Khẩu',
  or: 'Hoặc',
  continueWithFacebook: 'Đăng nhập với Facebook',
  continueWithGoogle: 'Đăng nhập với Google',
  admin: 'Admin',
  goToAdminPage: 'Vào Admin',
  updateData: 'Cập Nhật Dữ Liệu',
  updatePhoneNumber: 'Cập Nhật Số Điện Thoại',
  addToDatabase: 'Thêm Vào Dữ Liệu',
  deleteFromDatabase: 'Xóa Khỏi Dữ Liệu',
  editDatabase: 'Thay Đổi Dữ Liệu',
  returnHome: 'Trở Về Home Page',
  inputProductName: 'Nhập Tên Sản Phẩm',
  inputLink: 'Link',
  inputProductPrice: 'Nhập Giá Sản Phẩm',
  inputProductID: 'Nhập ID Sản Phẩm',
  inputProductNumber: 'Nhập Số Lượng Sản Phẩm',
  inputNumber: 'Nhập Số Lượng',
  inputProductImage: 'Ảnh:',
  inputProductUrlImage: 'Nhập URL ảnh sản phẩm nếu có',
  inputProductDescription: 'Nhập Mô Tả Sản Phẩm',
  productDescription: 'MÔ TẢ SẢN PHẨM',
  search: 'Tìm kiếm',
  stocking: 'Còn Hàng',
  productType: 'Loại',
  inInventory: 'Trong Kho',
  addToCart: 'Thêm Vào Giỏ Hàng',
  byNow: 'Mua ngay',
  deleteThisProduct: 'Xóa mặt hàng này',
  userInfo: 'Hồ Sơ Của Tôi',
  userName: 'Tên',
  pleaseCorrectThePhoneNumber: 'Vui lòng nhập đúng số điện thoại!',
  pleaseCorrectTheAddress: 'Vui lòng nhập đúng địa chỉ!',
  phoneNumber: 'Số Điện Thoại',
  goToShoppingCart: 'Vào Giỏ Hàng của Tôi!',
  guarantee: 'Bảo Hành',
  excessOfAuthorizedOrdersOrOutOfStock: 'Vượt quá số lượng đặt hàng cho phép hoặc hết hàng!',
  delete: 'Xóa',
  product: 'Sản phẩm',
  unitPrice: 'Đơn giá',
  amount: 'Số lượng',
  amountOfMoney: 'Số tiền',
  manipulation: 'Thao tác',
  seeMore: 'Xem Thêm',
  seeAll: 'Xem Tất Cả',
  addToCartSuccessfully: 'Đã Thêm Vào Giỏ Hàng Thành Công',
  addError: 'Thêm Không Thành Công',
  addSuccess: 'Thêm Thành Công',
  updateSuccess: 'Thay Đổi Thành Công',
  error: 'Lỗi',
  pleaseInputFullyData: 'Vui Lòng Nhập Đầy Đủ Data!',
  doYouWantToDelete: 'Bạn Có Chắc Chắn Muốn Xóa?',
  willReturnAdminPage: 'Sẽ trở về trang Admin!',
  deleteThisItemFromShoppingCart: 'Xóa vật phẩm này khỏi giỏ hàng?',
  deleteThisItemFromWaitingCart: 'Hủy Đơn Hàng Nảy?',
  save: 'Lưu',
  pay: 'Thanh toán',
  city: 'Thành Phố',
  order: 'Đơn Hàng',
  message: 'Tin Nhắn',
  totalCostOfGoods: 'Tổng Tiền Hàng',
  shippingFee: 'Phí Vận Chuyển',
  contact: 'Contact',
  preOrder: 'Mua Hàng',
  wrongInformation: 'Thông tin sai!',
  doNotHaveDataToPay: 'Chưa có hàng để thanh toán!',
  pleaseAddTheOrder: 'Vui lòng thêm đơn hàng!',
  returnToHome: 'Trở Về Trang Chủ',
  continueToShopping: 'Tiếp Tục Mua Hàng',
  shopWillContactSoon: 'Shop sẽ liên hệ bạn trong thời gian sớm nhất.',
  buySuccessfully: 'Mua Hàng Thành Công!',
  shopWillContactYou: 'Shop sẽ liên hệ bạn!',
  searchResultFor: 'Kết quả tìm kiếm cho',
  sortBy: 'Sắp xếp theo',
  newest: 'Mới Nhất',
  pri: 'Giá',
  lowToHigh: 'Thấp đến Cao',
  highToLow: 'Cao đến Thấp',
  samePrice: 'Đồng Giá',
  newProduct: 'Sản phẩm mới',
  deleteUser: 'Yêu cầu xóa dữ liệu người dùng khỏi hệ thống.',
  deleteUserInfo: 'Sau khi nhấn xóa thì toàn bộ dữ liệu cá nhân của bạn bao gồm Tên, Email, Số điện thoại, địa chỉ, ảnh đại diện,.. sẽ bị xóa hoàn toàn khỏi hệ thống cúa White Bear bao gồm web và ứng dụng giành cho điện thoại.\n Cảm ơn bạn đã sử dụng dịch vụ của White Bear.\n Chào thân ái và quyết thắng ^^.',
}