import React from 'react'
import './ScrollList.css'
import { useHistory } from 'react-router-dom'
import { Strings, unitItemWidth, priceFormatter } from '../configs'
import { addItemToCart } from '../configs/FireBase'
import { showAlert } from '../common/common'
import { ImageVideoComponent } from '../common/ImageVideoComponent'

export default function UnitItem({ item, uid }) {
  const history = useHistory()

  function onClickItem() {
    history.push(`/detail?id=${item.uuid}`)
  }

  async function onClickAddToCart() {
    if (!uid) {
      showAlert(Strings.pleaseLogin, Strings.youMustLoginToAddToCart, Strings.login, Strings.cancel, () => {
        history.push('/login')
      })
    } else {
      const result = await addItemToCart(uid, item.uuid, 1, item.inventory)
      if (result) {
        showAlert(Strings.addToCartSuccess, '', 'OK')
      }
      else {
        showAlert(Strings.excessOfAuthorizedOrdersOrOutOfStock, '', 'OK')
      }
    }
  }

  return (
    <div key={item} style={{ width: unitItemWidth }} className='li-test-home-page'>
      <a className="li-test-home-page-content" href={`/detail?id=${item.uuid}`}>
        <ImageVideoComponent style={{ width: unitItemWidth, height: unitItemWidth }} className='image-test' item={item} />
        <div className='item-title-home-page'>{item.name}</div>
      </a>
      <div className='bottom-item'>
        <div onClick={onClickItem} role='button' className='price-item-home-page'>
          <span className='number-text'>{priceFormatter.format(item.price)}</span>
        </div>
        <div role='button' onClick={onClickAddToCart} className='plus-unit-item'>
          <svg className='plus-unit-image' enableBackground="new 0 0 15 15" viewBox="0 0 15 15"><path d="m .5.5h2.2l2.5 10.5h7.2l2.1-7.5h-10.8" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"></path><circle cx="6" cy="13.5" r="1"></circle><circle cx="11.5" cy="13.5" r="1"></circle><path d="m7.5 7h3" fill="none" strokeLinecap="round" strokeMiterlimit="10"></path><path d="m9 8.5v-3" fill="none" strokeLinecap="round" strokeMiterlimit="10"></path></svg>
        </div>
      </div>
    </div>
  )
}