import React from 'react'
import '../styles/Home/Home.css'
import HomePage from '../components/Home/HomePage'
import Header from '../common/Header'

const Home = () => {
  return (
    <Header showShortBar>
      <HomePage />
    </Header>
  )
}

export default Home