import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import '../../styles/ShoppingCart/ShoppingCartScreen.css'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import { getItemFromCart, deleteItemFromCart, getShippingItem,getWaitingItem, cancelWaitingItem } from '../../configs/FireBase'
import LoadingOverlay from 'react-loading-overlay'
import CartItem from './CartItem'
import ShippingItem from './ShippingItem'
import { Strings, priceFormatter } from '../../configs'
import { showAlert } from '../../common/common'
import ShippingComponent from './ShippingComponent'
import WaitingComponent from './WaitingComponent'

export default function ShoppingCartScreen() {
  const [data, setData] = useState([])
  const [shipData, setShipData] = useState([])
  const [waitData, setWaitData] = useState([])
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const user = useSelector(state => state.app.user)
  useEffect(() => {
    window.scrollTo(0,0)
    getData()
  }, [])
  async function getData() {
    setIsLoading(true)
    const serverData = await getItemFromCart(user.uid)
    const shippingData = await getShippingItem(user.uid)
    const waitingData = await getWaitingItem(user.uid)
    setWaitData(waitingData)
    setShipData(shippingData)
    if (serverData) {setData(serverData)}
    setIsLoading(false)
  }
  async function onDeleteItem(uuid) {
    showAlert(Strings.deleteThisItemFromShoppingCart, '', Strings.delete, Strings.cancel, async() => {
      setIsLoading(true)
      await deleteItemFromCart(user.uid, uuid)
      getData()
    })
  }

  function countTotalMoney(listItem) {
    let total = 0
    for (let index = 0; index < listItem.length; index++) {
      total += listItem[index].price * listItem[index].cartNum
      
    }
    return priceFormatter.format(total)
  }

  async function onClickPayment() {
    history.push('/payment')
  }

  async function deleteWaitingItem(item) {
    showAlert(Strings.deleteThisItemFromWaitingCart, '', Strings.delete, Strings.cancel, async() => {
      setIsLoading(true)
      await cancelWaitingItem(item)
      getData()
    })
  }

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text='Loading...'
      styles='overlay'
    >
      <div className='shopping-cart-content'>
        <div className='shopping-cart-container'>
          <div className="cart-page-product-header">
            <div className="cart-item__cell-checkbox">
              <label className="stardust-checkbox">
                <svg className='stardust-remove-button' xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24" enableBackground="new 0 0 24 24">
                  <path d="M 2 2 L 2 4 L 4.28125 4 L 6.90625 11.8125 L 5.40625 13.8125 L 5.375 13.8125 C 4.9155448 14.455737 4.8951058 15.315107 5.21875 15.9375 C 5.5423942 16.559893 6.225 17 7 17 L 20 17 L 20 15 L 7 15 C 7.0020068 14.994613 6.9898637 14.98294 7 14.96875 L 8.5 13 L 17.90625 13 C 18.30625 13 18.7125 12.80625 18.8125 12.40625 L 21.90625 5.40625 C 22.20625 4.70625 21.7 4 21 4 L 6.375 4 L 5.9375 2.6875 L 5.71875 2 L 5 2 L 2 2 z M 7 18 C 5.8954305 18 5 18.895431 5 20 C 5 21.104569 5.8954305 22 7 22 C 8.1045695 22 9 21.104569 9 20 C 9 18.895431 8.1045695 18 7 18 z M 18 18 C 16.895431 18 16 18.895431 16 20 C 16 21.104569 16.895431 22 18 22 C 19.104569 22 20 21.104569 20 20 C 20 18.895431 19.104569 18 18 18 z"/>
                </svg>
              </label>
            </div>
            <div className="cart-page-product-header__product">{Strings.shoppingCart}</div>
            {!isMobile && <div className="cart-page-product-header__unit-price">{Strings.unitPrice}</div>}
            {!isMobile && <div className="cart-page-product-header__quantity">{Strings.amount}</div>}
            {!isMobile && <div className="cart-page-product-header__total-price">{Strings.amountOfMoney}</div>}
          </div>
          <div>
            {data.map((item) => (
              <CartItem getData={getData} key={item.uuid} item={item} user={user} onDeleteItem={onDeleteItem} />
            ))}
          </div>
        </div>
        {isLoading || data.length === 0 ? null : <div className='cart-payment-container-top'>
          <div className='cart-payment-container'>
            <div className="cart-total-money">{Strings.totalPrice}: <span className="cart-total-money-text">{countTotalMoney(data)}</span></div>
            <button onClick={onClickPayment} className="cart-payment-button">{Strings.preOrder}</button>
          </div>
        </div>}
        <WaitingComponent deleteWaitingItem={deleteWaitingItem} waitData={waitData} />
        {shipData.length > 0 ? <ShippingComponent shipData={shipData} /> : null}
      </div>
    </LoadingOverlay>
  )
}
