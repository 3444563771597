import React from 'react'
import '../styles/Home/Home.css'
import ShoppingCartScreen from '../components/ShoppingCart/ShoppingCartScreen'
import Header from '../common/Header'

export default function ShoppingCart() {
  return (
    <Header>
      <ShoppingCartScreen />
    </Header>
  )
}