import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import queryString from 'query-string'
import moment from 'moment'
import { Strings, checkAdminUser } from '../../configs'
import '../../styles/Login.css'
import bearImage from '../../assets/whitebearlazy.png'
import { storage, updateItem, getItemByID, deleteImage } from '../../configs/FireBase'
import UploadImage from '../../components/UploadImage'
import PacmanLoader from 'react-spinners/PacmanLoader'
import TextAreaAutoSize from '../../common/TextAreaAutoSize'
import { itemTypeDoc, itemGuarantee } from '../../configs/ArgumentDoc'
import { showAlert } from '../../common/common'

export default function UpdateData() {
  const user = useSelector(state => state.app.user)
  const history = useHistory()
  const values = queryString.parse(location.search)
  const [isAdmin, setIsAdmin] = useState(false)
  const [name, setName] = useState('')
  const [image, setImage] = useState([])
  const [currentImage, setCurrentImage] = useState([])
  const [price, setPrice] = useState(null)
  const [inventory, setInventory] = useState(null)
  const [description, setDescription] = useState('')
  const [itemType, setItemType] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [guarantee, setGuarantee] = useState(0)
  const [linkData, setLinkData] = useState(null)

  useEffect(() => {
    firstCheck()
  }, [])

  async function firstCheck() {
    setIsLoading(true)
    const isAd = await checkAdminUser(user)
    setIsAdmin(isAd)
    if (isAd) {
      const responseData = await getItemByID(values.id)
      setName(responseData.name)
      setCurrentImage(responseData.image)
      setPrice(responseData.price)
      setInventory(responseData.inventory)
      setDescription(responseData.description)
      setItemType(responseData.itemType)
      setGuarantee(responseData.guarantee)
      setLinkData(responseData.linkData)
    }
    setIsLoading(false)
    
  }

  const uploadImage = (item) => {
    return new Promise((resolve, reject) => {
      const uuid = `${moment().format('YYYYMMDDHHmmss')}${item.lastModified}.${item.name.split('.').pop()}`
      const fileType = item.type.includes('image') ? 'images' : 'videos'

      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storage.ref(`${fileType}/${uuid}`).put(item)

      uploadTask.on('state_changed', // or 'state_changed'
        (snapshot) => {
        }, 
        (error) => { 
          reject(null)
        }, 
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            resolve({ url: downloadURL, type: item.type.includes('image') ? 1 : 2 })
          })
        }
      )
    })
  }

  async function handleUpload() {
    let listImageUrl = []
    if (image.length > 0) {
      if (currentImage && currentImage.length > 0) {
        await deleteImage(currentImage)
      }
      const promises = image.map(async item => {
        const numFruit = await uploadImage(item)
        return numFruit
      })
      listImageUrl = await Promise.all(promises)
    } else {
      listImageUrl = currentImage
    }
    const result = await updateItem({
      uuid: values.id,
      name,
      image: listImageUrl,
      price,
      inventory,
      description,
      itemType,
      guarantee,
      linkData,
    })
    clearData()
  }

  async function handleSubmitAddData() {
    if (!name || !price || !inventory) {
      showAlert(Strings.error, Strings.pleaseInputFullyData, 'OK')
    } else {
      setIsLoading(true)
      handleUpload()
    }
  }

  function clearData() {
    showAlert(Strings.updateSuccess, Strings.willReturnAdminPage, 'OK', null, () => {
      history.push('/admin')
    })
  }

  if (isAdmin) {return (
    <LoadingOverlay
      active={isLoading}
      spinner={<PacmanLoader />}
    >
      <div className="full-body-update-admin">
        <div className='full-body'>
          <div className="app-container">
            <div className="navigation-bar">
              <a onClick={() => history.goBack()}>
                <svg viewBox="0 0 22 17" className="back-button">
                  <g stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(-3, -6)">
                    <path d="M5.78416545,15.2727801 L12.9866648,21.7122915 C13.286114,22.0067577 13.286114,22.4841029 12.9866648,22.7785691 C12.6864297,23.0738103 12.200709,23.0738103 11.9004739,22.7785691 L3.29347136,15.0837018 C3.27067864,15.0651039 3.23845445,15.072853 3.21723364,15.0519304 C3.06240034,14.899273 2.99480814,14.7001208 3.00030983,14.5001937 C2.99480814,14.3002667 3.06240034,14.1003396 3.21723364,13.9476821 C3.23845445,13.9275344 3.2714646,13.9345086 3.29425732,13.9166857 L11.9004739,6.22026848 C12.200709,5.92657717 12.6864297,5.92657717 12.9866648,6.22026848 C13.286114,6.51628453 13.286114,6.99362977 12.9866648,7.288096 L5.78416545,13.7276073 L24.2140442,13.7276073 C24.6478918,13.7276073 25,14.0739926 25,14.5001937 C25,14.9263948 24.6478918,15.2727801 24.2140442,15.2727801 L5.78416545,15.2727801 Z"></path>
                  </g>
                </svg>
              </a>
              <div className="login-title">
                <div onClick={() => history.push('/admin')} className="login-text">{Strings.admin}</div>
              </div>
            </div>
            <div>
              <div className="form-container">
                <div className="logo-container">
                  <img className="logo" src={bearImage} />
                </div>
                <div className="user-name-container">
                  <input onChange={(e) => setName(e.target.value)} className="user-name-input" type="text" placeholder={Strings.inputProductName} maxLength="5164" autoCorrect="off" autoCapitalize="off" value={name} />
                </div>
                <div className="user-name-container">
                  <input onChange={(e) => setPrice(Number(e.target.value))} className="user-name-input" type="number" placeholder={Strings.inputProductPrice} maxLength="128" autoCorrect="off" autoCapitalize="off" value={price} />
                </div>
                <div className="user-name-container">
                  <input onChange={(e) => setInventory(Number(e.target.value))} className="user-name-input" type="number" placeholder={Strings.inputProductNumber} maxLength="128" autoCorrect="off" autoCapitalize="off" value={inventory} />
                </div>
                <TextAreaAutoSize text={description} setText={setDescription} containerClass="description-container" textAreaClass="description-text-box" />
                <div className="user-name-container">
                  <div>{Strings.productType}:</div>
                  <select defaultValue={itemType} className="type-input" onChange={(e) => setItemType(e.target.value)}>
                    <option value={0}>{itemTypeDoc[0]}</option>
                    <option value={1}>{itemTypeDoc[1]}</option>
                    <option value={2}>{itemTypeDoc[2]}</option>
                    <option value={3}>{itemTypeDoc[3]}</option>
                    <option value={4}>{itemTypeDoc[4]}</option>
                    <option value={5}>{itemTypeDoc[5]}</option>
                  </select>
                </div>
                <div className="user-name-container">
                  <div>{Strings.guarantee}:</div>
                  <select className="type-input" onChange={(e) => setGuarantee(e.target.value)}>
                    <option value={0}>{itemGuarantee[0]}</option>
                    <option value={1}>{itemGuarantee[1]}</option>
                    <option value={11}>{itemGuarantee[11]}</option>
                    <option value={111}>{itemGuarantee[111]}</option>
                    <option value={1111}>{itemGuarantee[1111]}</option>
                  </select>
                </div>
                <UploadImage onSelectImage={setImage} />
                <div className="user-name-container">
                  <input onChange={(e) => setLinkData(e.target.value)} className="user-name-input" type="text" placeholder={Strings.inputLink} autoCorrect="off" autoCapitalize="off" value={linkData} />
                </div>
                <div className='link-data' onClick={() => window.open(linkData, '_blank')}>{linkData}</div>
                <button onClick={handleSubmitAddData} className={'login-button-enable'}>{Strings.updateData}</button>
              </div>
            </div>
          </div>
          <div className='admin-footer' />
        </div>
      </div>
    </LoadingOverlay>
    
  )}
  else {
    return null
  }
}