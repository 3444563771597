import { handleActions } from 'redux-actions'
import initState from '../initState'
import { reset, updateUser, setFirstTimeTutorial, setIsShowLoginModal } from '../actions'

export default handleActions({
  [reset]: (state) => ({
    ...state,
    ...initState.app,
  }),
  [updateUser]: (state, { payload: { user } }) => ({
    ...state,
    user,
  }),
  [setFirstTimeTutorial]: (state, { payload: { isFirstTime } }) => ({
    ...state,
    isFirstTime,
  }),
  [setIsShowLoginModal]: (state, { payload: { isShowLoginModal } }) => ({
    ...state,
    isShowLoginModal,
  }),
}, initState.app)
