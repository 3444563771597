import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import '../../styles/Detail/UserDetailPage.css'
import { useSelector, useDispatch } from 'react-redux'
import LoadingOverlay from 'react-loading-overlay'
import { Strings, checkAdminUser } from '../../configs'
import { updateUser, logOut, getUserInformation, deleteUser } from '../../configs/FireBase'
import { updateUser as updateUserStore } from '../../store/actions'
import { showAlert } from '../../common/common'

export default function UserDetailPage() {
  const dispatch = useDispatch()
  const history = useHistory()
  const userStore = useSelector(state => state.app.user)
  const [isLoading, setIsLoading] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isAdminUser, setIsAdminUser] = useState(false)
  const [user, setUser] = useState({})
  const [error, setError] = useState('')

  useEffect(() => {
    getFirstData(userStore.uid)
  }, [])

  async function getFirstData(id) {
    setIsLoading(true)
    const data = await getUserInformation(id)
    const isAd = await checkAdminUser(userStore)
    setIsAdminUser(isAd)
    setUser(data)
    setIsLoading(false)
  }

  async function updateUserPhoneNumber() {
    setIsLoading(true)
    const phoneRegex = /([0])\d{9}/g
    if(phoneNumber.match(phoneRegex)) {
      setError('')
      await updateUser({ phoneNumber, uid: user.uid })
      setUser({ ...user, phoneNumber })
      dispatch(updateUserStore({ ...user, phoneNumber }))
    } else {
      setError(Strings.pleaseCorrectThePhoneNumber)
    }
    setIsLoading(false)
  }

  function onInputPhoneNumber(number) {
    if(number.toString().length <=11) {
      setPhoneNumber(number)
    }
  }

  function onLogout() {
    logOut()
    dispatch(updateUserStore(null))
    history.push('/home')
  }

  function onDeleteUser() {
    showAlert(Strings.deleteUser, Strings.deleteUserInfo, 'Xóa', 'Hủy', () => {
      deleteUser(userStore.uid, onLogout)
    })
  }

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text='Login...'
    >
      <div className='user-detail-container'>
        <div className='user-detail-content'>
          <div className='user-detail-header'>
            <div className='user-detail-title'>{Strings.userInfo}</div>
          </div>
          <div className='user-detail-info'>
            <div className='user-detail-image-container'>
              <img className='user-detail-image' src={user.photoURL} />
            </div>
            <div className='user-detail-name-container'>
              <div className='user-detail-name-label'>{Strings.userName}:</div>
              <div className='user-detail-name-text'>{user.displayName}</div>
            </div>
            <div className='user-detail-name-container'>
              <div className='user-detail-name-label'>Email:</div>
              <div className='user-detail-name-text'>{user.email}</div>
            </div>
            <div className='user-detail-name-container'>
              <div className='user-detail-name-label'>Phone:</div>
              {user.phoneNumber ? <div className='user-detail-name-text'>{user.phoneNumber}</div> : (
                <div className='user-detail-phone-container'>
                  <div className='user-detail-phone-content'>
                    <input maxLength='11' size='11' onChange={(e) => onInputPhoneNumber(e.target.value)} value={phoneNumber} type='number' className='user-detail-phone-input' />
                  </div>
                </div>
              )}
            </div>
            <button onClick={onDeleteUser} className='update-button'>
              <div className='update-button-text'>{Strings.deleteUser}</div>
            </button>
            <div className='user-detail-error-label'>{error}</div>
            {user.phoneNumber ? null : <button onClick={updateUserPhoneNumber} className='update-button'>
              <div className='update-button-text'>{Strings.updatePhoneNumber}</div>
            </button>}
            <button onClick={() => history.push('/shopping')} className='update-button'>
              <div className='update-button-text'>{Strings.goToShoppingCart}</div>
            </button>
            {isAdminUser ? (
              <button onClick={() => history.push('/admin')} className='update-button'>
                <div className='update-button-text'>{Strings.goToAdminPage}</div>
              </button>
            ) : null}
          </div>
        </div>
        <div className='user-bottom-container'>
          <div onClick={onLogout} role='button' className='logout-button'>
            <div className='logout-button-text'>Logout</div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  )
}